<template>
    <div class="editPassWord">
        <div class="loginBox">
            <p class="boxName">修改密码</p>
            <p class="boxDes">修改密码前，需要验证手机号</p>
            <div class="formItem mgb0">
                <div class="icon"><div class="telIcon"></div></div>
                <input type="text" v-model="cellphone" @change="verify" class="cellphone" placeholder="请输入登录手机号">
            </div>
            <p class="errHint">{{verifyPhone}}</p>
            <div class="formItem mgb0">
                <div class="icon"><div class="codeIcon"></div></div>
                <input type="text" v-model="code" class="code" placeholder="请输入验证码">

                <button class="getCode" @click="getCode" v-if="isGetCode == false">{{codeText}}</button>
                <button class="getCode isGetCode"  v-if="isGetCode == true">{{timeNum}} s</button>
            </div>
            <p class="errHint">{{verifyCode}}</p>
            <div class="formItem mgb0">
                <div class="icon"><div class="passWordIcon"></div></div>
                <input type="text" v-model="password" class="password" placeholder="请输入新密码">
            </div>
            <p class="errHint">{{verifyPassword}}</p>
            <button class="loginBtn" @click.stop="sureEdit">确认修改</button>
        </div>
    </div>
</template>

<script>
export default {
    name:'editPassWord',
    data(){
        return {
          isGetCode: false,
          timeNum: 60,
          cellphone: null,
          code: null,
          password: null,
          verifyPhone: '',
          verifyCode: '',
          verifyPassword: '',
          codeText: '获取验证码'
        }
    },
    created(){

    },
    methods: {
      //验证手机
      verify() {
        if (this.cellphone == null && this.cellphone == '') {
          this.verifyPhone = '请输入手机号';
        } else {
          if(!(/^1\d{10}$/.test(this.cellphone))){
            this.verifyPhone = '手机号格式错误，请重新输入';
          } else {
            this.verifyPhone = '';
          }
        }
      },
      //确认修改
      sureEdit() {
        let that = this;
        let obj = {};
        if (this.cellphone == null) {
          this.verifyPhone = '请输入手机号';
          return;
        }
        if (this.code == null) {
          this.verifyCode = '请输入验证码';
          return;
        }
        if (this.password == null) {
          this.verifyPassword = '请输入新密码';
          return;
        }
        obj.cellphone = this.cellphone;
        obj.code = this.code;
        obj.password = this.password;
        obj.key = localStorage.getItem('key');
        this.axios.post('/api/user/password',obj).then((res)=>{
          if (res.data.code == 20000) {
            alert('修改成功');
            that.$emit("listenEditSuccess");
          } else {
            alert(res.data.errMsg);
          }
        }).catch((error)=>{
          console.log(error);
        })
      },
      //获取验证码
      getCode() {
        let that = this;
        let obj = {};
        if (this.cellphone == null) {
          this.verifyPhone = '请输入手机号';
          return;
        }
        that.isGetCode = true;
        let codeTime = setInterval(function () {
          if (that.timeNum == 1) {
            that.isGetCode = false;
            that.codeText = '重新获取';
            that.timeNum = 60;
            clearInterval(codeTime);
            return;
          }
          that.timeNum = that.timeNum - 1;
        }, 1000);
        obj.cellphone = this.cellphone;
        this.axios.post('/api/user/verification',obj).then((res)=>{
          if (res.data.code == 20000) {
            alert('已发送')
          } else {
            alert(res.data.errMsg);
          }
        }).catch((error)=>{
          console.log(error);
        })
      }
    }
}
</script>
<style scoped>
.editPassWord {
  width: 100%;
  height: 100%;
}
.colorCCC {
  color: #CCC;
}
.mgb0 {
  margin-bottom: 0 !important;
}
.errHint {
  margin-bottom: 16px;
  height: 16px;
  font-size: 12px;
  color: #FF5159;
  text-align: left;
}
.loginBox {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 24px 30px;
  box-sizing: border-box;
  width: 430px;
  height: 468px;
  background-color: #fff;
}
.boxName {
  margin-bottom: 8px;
  height: 20xp;
  font-size: 20px;
  color: #0D141E;
  text-align: left;
  font-weight: bold;
}
.boxDes {
  margin-bottom: 40px;
  height: 16px;
  font-size: 16px;
  color: #5D646E;
  text-align: left;
  font-weight: bold;
}
.turnVip {
  color: #2B8DF7;
}
.formItem {
  margin-bottom: 32px;
  display: flex;
  align-items: center;
  padding: 10px 0;
  width: 370px;
  height: 50px;
  border: 1px solid #E6E6E6;
  border-radius: 4px;
  box-sizing: border-box;
}
.passwordItem {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 20px;
  width: 370px;
  height: 20px;
  line-height: 20px;
  text-align: right;
  color: #0D141E;
  font-size: 16px;
  font-weight: bold;
}
.icon {
  flex: 0 0 51px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 52px;
  height: 30px;
  border-right: 1px solid rgba(93,100,110,0.2);
}
.telIcon {
  width: 14px;
  height: 22px;
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAsBAMAAACEWcUKAAAAGFBMVEUAAABcZG5dZG5dZW5dY25eZW5bZG1dZG7h4FUdAAAAB3RSTlMAf/LQyG0c4F5htQAAAFFJREFUKM9jYGB1KocClQAGBjbzcjgoTmBgLkcCBgzhyNxSBndkbgmDOjK3iKG8kAEOxMtHuYOSiwxI5KqDSSCARLc7MrcELamgJSS0ZIaWCAGHyoTRNDU38wAAAABJRU5ErkJggg==');
  background-size: 14px 22px;
}
.codeIcon {
  width: 17px;
  height: 22px;
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACIAAAAsCAMAAAA3k5qAAAAAOVBMVEUAAABdZG5dZW9eZG5aYG5dZG5dZG5fX2xeZHJdZG5cY25cY25dZW5eZG9dZW9dZG5AQIAAAP9dZG6IuCEjAAAAEnRSTlMA863FJZntGBO3uZWJemVKBAGJBH7GAAAAmklEQVQ4y9XU3QrCMAyG4SXZ1tb9f/d/sbLRiYXEKEVx70Gh8ECP+jW5lglKxO1D9DDqT8Mw40zIJpQJXvQZaZR+TnK1ZJ2FSObVJlFwJNEimwAyTfu5GWQBhv06AItBRiAe7wGjQTrgvHdfJOGGp1JQSCEAVgjKyCe4PiGfcCmSQkIqtiLU/oB/+bA+qV8pfw79UX1nmv2BvwPpOTDvBkGlZQAAAABJRU5ErkJggg==');
  background-size: 17px 22px;
}
.passWordIcon {
  width: 16px;
  height: 21px;
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAqBAMAAAAgz0ShAAAALVBMVEUAAABdZG5dZG5eZG5cXWxdZG5dZW9aYG5dZG5dZG5dZW5dY25aaWldZG5dZG5Z5SrfAAAADnRSTlMA2vBSDcysJfWkiVoRu6iKpc0AAAC0SURBVCjPY4AASyHFyQxIwPAdEAgj+FzvwGABXGDdu3eNEu/evYLxmd+9k3Zg2fjunQFUgPHdWwcGBpZ77wSgAnbvRECU47vHUIF57zaAKO53L6ECci8cQBRL30OogB6UIfcIKvDuKYSOe0emAHvSOySgVsBQ9A4FqDPkoQo8Y9BDFXjE8A4N0FLgoYmzHIqADAPDQRQBA2DYowiAQh2fAEILpqGY1tLHtxiBjBENGBGFEZUAjciwkc5WqtkAAAAASUVORK5CYII=');
  background-size: 16px 21px;
}
.cellphone, .password {
  flex: 1;
  padding-left: 16px;
  height: 30px;
  border: none;
  outline: none;
  font-size: 16px;
}
.code {
  flex: 1;
  padding-left: 16px;
  height: 30px;
  border: none;
  outline: none;
  font-size: 16px;
}
.getCode {
  margin-right: 12px;
  flex: 0 0 90px;
  height: 30px;
  border: none;
  outline: none;
  font-size: 12px;
  color: #fff;
  background-color: rgba(141,148,153,1);
  border-radius: 15px;
  cursor: pointer;
}
.isGetCode {
  flex: 0 0 60px;
}
.getCode:hover {
  background-color: rgba(141,148,153,0.6);
}
.passwordLogin {
  cursor: pointer;
}
.turnPassword {
  margin-left: 5px;
  width: 14px;
  height: 14px;
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAcCAMAAABF0y+mAAAAS1BMVEUAAAAMFR0NFB0NFB4NFB4NEx4NFB4NFB4MEh8PFx8AAAANFB7///9ydnwlLDX4+Pjq6+zd3t/DxceusLOKjZI8QUkaISqjpalPVFxYTNDqAAAAC3RSTlMAPuvn2MbCiVMhAR8vYCcAAACRSURBVCjPfZNXDsMwDEPleJtuusf9T1qgQ5CCgO/PIERo0PJnhFoiEEsNQza0BCU1J/UMR+6ihAUblqAadvipXetc7dc5Y5f86VOfzwMsTWToDOtpXh9GTMN2s17m+e56qjDc5tFYVymwvKx1kQiDt44Ch7emIrWlDdFR6BLo+tji2cnosVlMeMB4NHmo6Xd4A43rG3XGdanMAAAAAElFTkSuQmCC');
  background-size: 14px 14px;
  cursor: pointer;
}
.loginBtn {
  margin-bottom: 24px;
  width: 370px;
  height: 48px;
  background-color: rgba(43,141,247,1);
  font-size: 18px;
  color: #fff;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  outline: none;
}
.loginBtn:hover {
  background-color: rgba(43,141,247,0.8);
}
.signinBtn {
  width: 370px;
  height: 48px;
  font-size: 18px;
  background-color: #fff;
  color: rgba(67,201,167,1);
  border: 1px solid rgba(67,201,167,1);
  border-radius: 4px;
  cursor: pointer;
  outline: none;
}
.signinBtn:hover {
  background-color: #ecf5ff;
}
</style>


