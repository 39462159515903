import request from '../utils/request'

// 获取新课程
export const getModuleCourse = (params) => {
  return request({
    url: '/apicourse/course/write',
    method: 'get',
    params,
  })
}

// 获取新课程
export const getLessonDetail = (params) => {
  return request({
    url: '/apicourse/lesson/write',
    method: 'get',
    params: { ...params, platform: 1, page_size: 500, page: 1 },
  })
}

// 模块信息
export const getModuleDetail = (params) => {
  return request({
    url: '/apicourse/progress/list',
    method: 'get',
    params: { ...params, platform: 1 },
  })
}

// 初始化模块状态
export const initModelStatus = (params) => {
  return request({
    url: '/apicourse/progress/add',
    method: 'post',
    data: {
      ...params,
      platform: 1,
      payload: {},
      platform: 1,
      quiz_id: 0,
      quiz_type: 0,
      module_type: 1,
      stars: 0
    }
  })
}

// 获取机构定位信息
export const getOrganGeo = () => {
  return request({
    url: '/omocrm/api/v1/user/getStore',
    method: 'get',
  })
}

// 获取用户定位信息
export const getUserGeo = () => {
  return request({
    url: '/omocrm/api/v1/user/getUserWxPosition',
    method: 'get',
  })
}