<template>
  <div class="videoHome">
    <div class="header">
      <video-header
        @listenChooseCourse="operateCourse"
        @listenEditPassWordBox="editPassWordBox"
      ></video-header>
    </div>

    <div class="videoContent">
      <!-- 视频模块 -->
      <div
        class="videoBox"
        v-bind:class="hideSide == true ? 'right0' : ''"
        v-if="notCourse == false"
      >
        <video-play
          :videoid="videoPlays"
          :isNewCourse="isNewCourse"
          @handleVideoEnd="handleVideoEnd"
        ></video-play>
        <div class="hideSideBar" @click.stop="tiggleSideBar">
          <div class="packImg" :class="{ rotate: !hideSide }"></div>
        </div>
      </div>
      <!-- 课程侧栏 -->
      <div
        class="sideBar"
        v-bind:class="hideSide == true ? 'width0' : ''"
        v-if="notCourse == false"
      >
        <video-side-bar
          v-if="!isNewCourse && isNewCourse !== ''"
          :courseId="courseId"
          :refresh="refresh"
          @listenUpLoaad="showUpLoad"
          @listenExpire="showVipExpire"
          @listenSetUrl="setVideoUrl"
          @listenNotCourse="setNotCourse"
        ></video-side-bar>
        <new-video-side-bar
          ref="newCourseBar"
          v-if="isNewCourse && isNewCourse !== ''"
          :courseId="courseId"
          :refresh="refresh"
          @listenUpLoaad="showUpLoad"
          @listenExpire="showVipExpire"
          @listenSetUrl="setVideoUrl"
          @listenNotCourse="setNotCourse"
        />
      </div>
    </div>
    <!-- 暂无课程 -->
    <div class="notCourse" v-if="notCourse == true">
      <div class="qrcodeBox">
        <windows
          :head="head1"
          :headDes="headDes1"
          :headImg="headImg1"
          @listenRefresh="refreshInfo"
        ></windows>
      </div>
    </div>
    <!-- 选择课程包 -->
    <div class="chooseCourseModal zIndex400" v-if="chooseCourse == true">
      <div class="chooseCourseBox">
        <choosecourse @listenChangeCourseBox="changeCourseBox"></choosecourse>
        <div class="closeModal" @click.stop="chooseCourseModal"></div>
      </div>
    </div>
    <!-- 修改密码 -->
    <div
      class="videoModal zIndex300"
      v-bind:class="hideSide == true ? 'right0' : ''"
      v-if="closeEditPassWord == true"
    >
      <div class="editPassWordBox">
        <edit-pass-word @listenEditSuccess="editSuccess"></edit-pass-word>
        <div class="closeModal" @click.stop="closeEditPassWordModal"></div>
      </div>
    </div>
    <!-- vip到期二维码弹窗 -->
    <div
      class="videoModal zIndex100"
      v-bind:class="hideSide == true ? 'right0' : ''"
      v-if="closeVip == true"
    >
      <div class="qrcodeBox">
        <windows
          :head="head3"
          :headDes="headDes3"
          :headImg="headImg3"
          @listenRefresh="refreshInfo"
        ></windows>
        <!-- <div class="closeModal" @click.stop="closeVipModal"></div> -->
      </div>
    </div>
    <!-- 上传作业二维码弹窗 -->
    <div
      class="videoModal zIndex200"
      v-bind:class="hideSide == true ? 'right0' : ''"
      v-if="closeUpLoad == true"
    >
      <div class="qrcodeBox">
        <windows
          :head="head2"
          :headDes="headDes2"
          :headImg="headImg2"
          @listenRefresh="refreshInfo"
        ></windows>
        <div class="closeModal" @click.stop="closeUpLoadModal"></div>
      </div>
    </div>
    <!--二维码弹窗-->
    <el-dialog
      width="800"
      height="800"
      title
      lock-scroll
      custom-class="dialog-wrap"
      :show-close="false"
      :visible.sync="showQrCode"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <div>
        <div style="marginBottom: 20px">请打开微信扫一扫，扫描二维码登陆</div>
        <vue-qr :text="qrUrl" :size="150" :margin="0"></vue-qr>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import videoPlay from "../components/videoPlay";
import VueQr from 'vue-qr'
import videoSideBar from "../components/videoSideBar";
import NewVideoSideBar from "../components/NewVideoSideBar";
import videoHeader from "../components/videoHeader";
import editPassWord from "../components/editPassWord";
import choosecourse from "../components/choosecourse";
import windows from "../components/windows";
import { getModuleCourse, getUserGeo, getOrganGeo } from '../api/course'
import { sanShiCourse } from '../common/index'
export default {
  name: "videoHome",
  data () {
    return {
      src: "dede",
      videoPlays: {
        videoSrc: "",
        paused: false
      },
      showQrCode: false,
      hideSide: false,
      notCourse: false,
      chooseCourse: false,
      courseId: null,
      closeEditPassWord: false,
      closeQrcodeBox: false,
      closeUpLoad: false,
      closeVip: false,
      refresh: 0,
      timerInterval: null,
      organGeo: '',
      qrUrl: `https://yw.hexiaoxiang.com/statich5/hx/omo-geo.html?#/home?token=${localStorage.getItem('key')}`,
      // qrUrl: `http://192.168.12.17:1025/pages/hx/omo-geo.html#/home?token=${localStorage.getItem('key')}`,
      head1: "没有课程",
      headDes1: "你还没有订购相关课程，赶紧扫码订购吧",
      headImg1: "https://xcx.alicdn2.hexiaoxiang.com/web_static/VIPExpire.png",
      head2: "上传作业",
      headDes2: "提交本课作业后，点击刷新即可解锁下一课",
      headImg2:
        "https://hexiaoxiang-xcx.oss-cn-hangzhou.aliyuncs.com/web_static/upload.jpg",
      head3: "VIP已到期",
      headDes3: "你的vip课程已到期，扫一扫继续学习",
      headImg3: "https://xcx.alicdn2.hexiaoxiang.com/web_static/VIPExpire.png",
      isNewCourse: ''
    };
  },
  components: {
    videoPlay,
    videoSideBar,
    videoHeader,
    editPassWord,
    choosecourse,
    NewVideoSideBar,
    windows,
    VueQr,
  },
  watch: {
    courseId: function (val) {
      this.courseId = val;
      localStorage.setItem('courseId', val);
    },
  },
  mounted () {
    // 获取机构地理位置信息
    getOrganGeo().then(res => {
      console.log(res)
      if (res && res.resultObj) {
        this.organGeo = res.resultObj
      }
    }).finally(() => {
      this.setShowModules()
    })
  },
  methods: {
    //显示隐藏侧栏
    tiggleSideBar () {
      this.hideSide = !this.hideSide;
    },
    //设置暂无课程
    setNotCourse () {
      this.notCourse = true;
    },
    //刷新
    refreshInfo () {
      this.refresh += 1;
    },
    //关闭选择课程包
    chooseCourseModal () {
      this.chooseCourse = false;
      this.setVideoPlay();
    },
    //关闭修改密码
    closeEditPassWordModal () {
      this.closeEditPassWord = false;
      this.setVideoPlay();
    },
    //关闭vip到期
    closeVipModal () {
      this.closeVip = false;
      this.setVideoPlay();
    },
    //关闭上传作业
    closeUpLoadModal () {
      this.closeUpLoad = false;
      this.setVideoPlay();
    },
    //控制视频暂停
    setVideoPaused () {
      this.videoPlays.paused = true;
    },
    //控制视频播放
    setVideoPlay () {
      this.videoPlays.paused = false;
    },
    //设置视频url
    setVideoUrl (url) {
      this.videoPlays.videoSrc = ''
      // 判断该课程是不是三师课程
        if (sanShiCourse.indexOf(this.courseId) !== -1 && this.organGeo && this.organGeo.Position && this.organGeo.PositionOn && this.organGeo.PositionOn === 1) {
          // 开启轮询
          this.loopGetGeo(url);
        } else {
          this.videoPlays.videoSrc = url;
        }
    },
    // 处理新课程播放完成事件
    handleVideoEnd () {
      this.$refs.newCourseBar.handleVideoEnd()
    },
    //显示vip过期
    showVipExpire (type) {
      if (type == "true") {
        this.closeVip = true;
        this.setVideoPaused();
      } else {
        this.closeVip = false;
      }
    },
    //设置展示哪些模块 新课程是一个新的接口
    // 产品需求有新课程展示新课程 此处必须先拉接口 判断新课程是否符合展示 逻辑同切换课程模块 要改的话两处一起
    async setShowModules () {
      let vipType = localStorage.getItem("vipType") || 0;
      let expireTime = localStorage.getItem("expireTime") || 0;
      const { data } = await getModuleCourse({
        platform: 1,
        page: 1,
        page_size: 500
      })
      if (data && data.items && data.items.length > 0) {
        const { items } = data
        const moduleCourseList = []
        items.forEach(val => {
          const { classroom_state, expire_time } = val
          if (classroom_state === 3) {
            moduleCourseList.push(val)
          }
        })
        const newCourseList = moduleCourseList.filter((item) => item && item.checked)
        this.isNewCourse = !!newCourseList.length > 0;
        newCourseList.length > 0 ? this.courseId = newCourseList[0].course_id : ''
        return
      }
      this.isNewCourse = false;
      // if (vipType != 2) {
      //   this.notCourse = true;
      // } else {
      if (expireTime > new Date().getTime()) {
        this.closeVip = true;
      }
      // }
    },
    //操作课程包选择弹窗
    operateCourse () {
      this.chooseCourse = !this.chooseCourse;
      if (this.chooseCourse === true) {
        this.setVideoPaused();
      } else {
        this.setVideoPlay();
      }
    },
    //向侧栏传id
    changeCourseBox (id, isNewCourse) {
      this.isNewCourse = isNewCourse
      this.courseId = id;
      this.chooseCourse = false;
      this.setVideoPlay();
    },
    //展示修改密码弹窗
    editPassWordBox () {
      this.closeEditPassWord = true;
      this.setVideoPaused();
    },
    //修改成功关闭弹窗
    editSuccess () {
      this.closeEditPassWord = false;
      this.setVideoPlay();
    },
    //显示上传作业弹窗
    showUpLoad (isHaveWork) {
      console.log(isHaveWork, 'ishave')
      this.head2 = isHaveWork ? '恭喜你学完了' : '上传作业'
      this.headDes2 = isHaveWork ? "点击刷新解锁下一课" : '提交本课作业后，点击刷新即可解锁下一课'
      this.closeUpLoad = true;
      this.setVideoPaused();
    },
    // 轮询获取用户定位
    loopGetGeo (url) {
      let tmpTimeOut;
      const getHandle = () => {
        getUserGeo().then(res => {
          if (res && res.resultObj) {
            const { Position } = res.resultObj;
            console.log(res)
            if (!Position) {
              // 弹出二维码
              if (!this.showQrCode) {
                this.showQrCode = true;
              }
              tmpTimeOut = setTimeout(() => {
                getHandle()
              }, 2000)
            } else {
              clearTimeout(tmpTimeOut)
              // 计算距离
              const tmpUserGeo = JSON.parse(Position)
              const tmpOrgGeo = JSON.parse(this.organGeo.Position)
              this.$jsonp(`https://apis.map.qq.com/ws/distance/v1/matrix?output=jsonp&key=QBLBZ-CNF3S-W3COH-67RQ7-MFBQ5-TBBBT&from=${tmpUserGeo.latitude},${tmpUserGeo.longitude}&to=${tmpOrgGeo.lat},${tmpOrgGeo.lng}`).then((res) => {
                if (res.status === 0) {
                  const { rows } = res.result;
                  const distance = rows.length > 0 && rows[0].elements[0].distance
                  if (distance > 300) {
                    this.$message({
                      dangerouslyUseHTMLString: true,
                      showClose: true,
                      duration: 0,
                      type: 'warning',
                      message: `<div style="line-height:30px"><div>对不起，您不在可登录地址范围内，禁止登录</div><div style="color:red">您可登录的范围：${this.organGeo.Title}半径300米以内</div></div>`
                    });
                  } else {
                    this.videoPlays.videoSrc = url
                  }
                  this.showQrCode = false;
                }
              });
            }
          }
        });
      }
      getHandle();

    },
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.videoHome {
  position: relative;
  width: 100vw;
  height: 100vh;
  background-color: #0000;
  overflow: hidden;
}
.header {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 80px;
  background-color: #313131;
}
.videoBox {
  position: absolute;
  top: 0;
  left: 0;
  right: 327px;
  bottom: 0;
  background-color: #000;
  overflow: hidden;
  transition: right 0.5s;
}
.sideBar {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 327px;
  background-color: #000;
  overflow: hidden;
  transition: width 0.5s;
}
.videoModal {
  position: absolute;
  top: 80px;
  left: 0;
  right: 310px;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  transition: right 0.5s;
}
.chooseCourseModal {
  position: absolute;
  top: 80px;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
}
.notCourse {
  position: absolute;
  top: 80px;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0);
}
.qrcodeBox {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 430px;
  height: 468px;
  background-color: #fff;
}
.chooseCourseBox {
  position: relative;
  width: 736px;
  height: 457px;
  background-color: #fff;
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media screen and (max-width: 1200px) {
  .chooseCourseBox {
    position: relative;
    width: 730px;
    height: 405px;
    background-color: #fff;
    border-radius: 8px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-right: 28px;
  }
}
.editPassWordBox {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 430px;
  height: 456px;
  background-color: #fff;
}
.closeModal {
  position: absolute;
  top: 24px;
  right: 24px;
  width: 16px;
  height: 16px;
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgBAMAAACBVGfHAAAAD1BMVEUAAACPlp6NlJiOlJqNlJn0uOiMAAAABHRSTlMAIeK/vYp1PgAAAG1JREFUKM9l0okJwCAQBdFNB2IqENJAIBWY/nsS5MMIE4iKPq9d6251fNdb3zg7+l99tgM8Y/+A2QqyQUpAKkBqAI0MQABpAkICIAGQAEgABECHpnhRbeuD6ei+nK7vACmEDrLSoEQplUq2nsMCTfQUD4T57q8AAAAASUVORK5CYII=');
  background-size: 16px 16px;
  cursor: pointer;
}
.zIndex100 {
  z-index: 1000;
}
.zIndex200 {
  z-index: 1200;
}
.zIndex300 {
  z-index: 1300;
}
.zIndex400 {
  z-index: 1400;
}
.videoContent {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  top: 80px;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100vw;
  background-color: #000;
}
.hideSideBar {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 30px;
  height: 40px;
  background-color: #111;
  cursor: pointer;
  border-radius: 80px 0 0 80px;
  z-index: 100;
}
.packImg {
  width: 16px;
  height: 20px;
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAMAAABg3Am1AAAAS1BMVEUAAABvb29vb29xcXFvb29vb29vb29wcHBvb29wcHBvb29zc3Nvb29vb29vb29vb29wcHBwcHBvb29vb29wcHBvb29tbW1xcXFwcHB5njg2AAAAGHRSTlMACMUi6K5l5F6/px/XspJsU/Dec0ZBFU1lfuL6AAAAiElEQVRIx+XW2wqAIBAEUMvu2d1q//9Lo0dZGpFCSfdxOQOCyChCTTYuw2GuTqX0s2+JaDRWDREVGfJUm/6eHXnamFfQl8wXMhVfQZ//xNeuvke+8+inkF4MzM/QS+ZFDjwOvDoSvoS4EpJZ2/NJMNF8nmijSlhKkSdWa+3ai93p6zCvSgsfcwHzzCzP0kNNGAAAAABJRU5ErkJggg==');
  background-size: 16px 20px;
  background-repeat: no-repeat;
}
.width0 {
  width: 0;
}
.right0 {
  right: 0;
}
.rotate {
  transform-origin: center center;
  transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transition: transform 0.2s;
  -moz-transition: -moz-transform 0.2s;
  -moz-transition: -moz-transform 0.2s;
  -o-transition: -o-transform 0.2s;
  -ms-transition: -ms-transform 0.2s;
}
.dialog-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
