<template>
  <div class="course">
    <div class="window" id="window">
      <div class="title">
        <h3>选择课程</h3>
        <h4>赶紧选择你要学习的课程吧</h4>
      </div>
      <div class="img">
         <div v-for="(item) in newCourseList" :style="change" v-bind:key="item.course_id" class="showimg">
          <img class="img1" :src="item.vertical_covers[0]" @click.stop="chooseCourse(item.course_id, true)" />
        </div>
        <div v-for="(item,index) in courselist" :style="change" v-bind:key="index" class="showimg">
          <img class="img1" :src="item.url" @click.stop="chooseCourse(item.courseId, false)" />
        </div>
      </div>
      <div class="footer" v-if="bigScreen">
        <div class="" v-bind:class="left==1? 'leftStop' : 'left'" @click.stop="lefts()"></div>
        <div class="" v-bind:class="right==1? 'rightStop' : 'right'" @click.stop="rights()"></div>
        <!-- <img class="left" :src="left" @click="lefts()" />
        <img class="right" :src="right" @click="rights()" /> -->
      </div>
     <div class="footer" v-if="!bigScreen">
        <div class="" v-bind:class="left==1? 'leftStop' : 'left'" @click.stop="leftsSmall()"></div>
        <div class="" v-bind:class="right==1? 'rightStop' : 'right'" @click.stop="rightsSmall()"></div>
        <!-- <img class="left" :src="left" @click="lefts()" />
        <img class="right" :src="right" @click="rights()" /> -->
      </div>
    </div>
  </div>
</template>

<script>
import { getModuleCourse } from "../api/course";

export default {
  data() {
    return {
      left: 1, //向左图标显示为禁用
      right: 0, //向右图标是否为禁用
      courselist: [], //课程列表
      newCourseList:[], // 新课程列表
      change: "", //图片切换的距离
      a: 0, //向左切换距离
      b: 0, //向右切换距离
      bigScreen: true //使用大屏幕的函数
    };
  },
  mounted() {
    var x = document.getElementById("window");
    var that = this;
    //判断大小屏幕
    window.onresize = function() {
      if (x.offsetWidth == 700) {
        that.bigScreen = false;
        that.change = "";
        that.a = 0;
        that.b = 0;
      } else {
        that.bigScreen = true;
        that.change = "";
        that.a = 0;
        that.b = 0;
      }
    };
    if (x.offsetWidth == 700) {
      this.bigScreen = false;
    } else {
      this.bigScreen = true;
    }
  },
  created() {
    this.getAllCourse();
  },
  methods: {
    //初始化左右图标
    init() {
      if (this.courselist.length + this.newCourseList.length <= 3) {
        this.right = 1;
      }
    },
    //获取所有课程包
    getAllCourse() {
      console.log(this.courselist);
      let that = this;
      let key = localStorage.getItem("key") || "";
      if (key != "") {
        getModuleCourse({
          platform: 1,
          page: 1,
          page_size: 500
        }).then(({ data })=> {
          this.handleNewCourse(data)
          // this.newCourseList = data.items
        }).catch(error => {
            console.log(error);
        });
        this.axios
          .get("/api/classroom/mine", { params: { key: key } })
          .then(res => {
            if (res.data.code == 20000) {
              let classrooms = res.data.data.classrooms;
              //设置课程列表
              that.courselist = classrooms.reduce(function(
                pre,
                val,
                index,
                arr
              ) {
                let obj = {};
                obj.courseId = val.gid;
                obj.url = val.courseCover;
                pre.push(obj);
                return pre;
              },
              []);
              that.init();
            } else {
              if (res.data.code == 40003) {
                that.$router.push({ path: "/login" });
                return;
              }
              that.$notify({
                showClose: true,
                message: res.data.errMsg,
                type: "error"
              });
            }
          })
          .catch(error => {
            console.log(error);
          });
      }
    },
    //大屏幕下点击向左箭头
    lefts() {
      if (this.courselist.length + this.newCourseList.length <= 3) {
        this.left = 1;
        this.right = 1;
      } else {
        if (this.a + this.b == 0) {
          this.left = 1;
        } else if (this.a + this.b == -239) {
          this.left = 1;
          this.right = 0;
          this.a += 239;

          let c = this.a + this.b;

          let left = "transform:translateX(" + c + "px)";

          this.change = left;
        } else {
          this.right = 0;

          this.a += 239;

          let c = this.a + this.b;

          let left = "transform:translateX(" + c + "px)";

          this.change = left;
        }
      }
    },
    //大屏幕下点击向右箭头
    rights() {
      if (this.courselist.length + this.newCourseList.length <= 3) {
        this.left = 1;
        this.right = 1;
      } else {
        if (this.a + this.b == -239 * (this.courselist.length + this.newCourseList.length - 3)) {
          this.right = 1;
        } else if (this.a + this.b == -239 * (this.courselist.length + this.newCourseList.length - 4)) {
          this.left = 0;
          this.right = 1;
          this.b += -239;

          let c = this.a + this.b;

          let right = "transform:translateX(" + c + "px)";

          this.change = right;
        } else {
          this.left = 0;

          this.b += -239;

          let c = this.a + this.b;

          let right = "transform:translateX(" + c + "px)";

          this.change = right;
        }
      }
    },

    // 处理新课程 按目前产品给的需求 暂时处理只展示开班且未过期 
    // 判断新课程是否符合展示 逻辑同videohome页面逻辑 要改的话两处一起
    handleNewCourse({ items }) {
      if(items && items.length === 0){
        return
      }
      const moduleCourseList = []
        items.forEach(val => {
          const { classroom_state, expire_time } = val
          if(classroom_state === 3 ){
            moduleCourseList.push(val)
          }
        })
      this.newCourseList = moduleCourseList.filter((item) => item && item.checked)
    },

    //小屏幕下点击向左箭头
    leftsSmall() {
      if (this.courselist.length + this.newCourseList.length <= 3) {
        this.left = 1;
        this.right = 1;
      } else {
        if (this.a + this.b == 0) {
          this.left = 1;
        } else if (this.a + this.b == -224) {
          this.right = 0;
          this.left = 1;
          this.a += 224;

          let c = this.a + this.b;

          let left = "transform:translateX(" + c + "px)";

          this.change = left;
        } else {
          this.right = 0;

          this.a += 224;

          let c = this.a + this.b;

          let left = "transform:translateX(" + c + "px)";

          this.change = left;
        }
      }
    },
    //小屏幕下点击向右箭头
    rightsSmall() {
      if (this.courselist.length + this.newCourseList.length <= 3) {
        this.left = 1;
        this.right = 1;
      } else if (this.a + this.b == -224 * (this.courselist.length + this.newCourseList.length - 4)) {
        this.left = 0;
        this.right = 1;
        this.b += -224;

        let c = this.a + this.b;

        let right = "transform:translateX(" + c + "px)";

        this.change = right;
      } else {
        if (this.a + this.b == -224 * (this.courselist.length + this.newCourseList.length - 3)) {
          this.right = 1;
        } else {
          this.left = 0;

          this.b += -224;

          let c = this.a + this.b;

          let right = "transform:translateX(" + c + "px)";

          this.change = right;
        }
      }
    },

    //选择课程
    chooseCourse(id,isNewCourse) {
      this.$emit("listenChangeCourseBox", id,isNewCourse);
    }
  }
};
</script>
<style scoped>
* {
  margin: 0;
  padding: 0;
}
.footer {
  padding-top: 30px;
  display: flex;
  justify-content: center;
}
.window {
  background-color: white;
  width: 1174px;
  height: 672px;
  text-align: center;
  overflow: hidden;
}

.title {
  margin-left: 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.left {
  width: 40px;
  height: 40px;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFAAAABQCAMAAAC5zwKfAAAAbFBMVEUAAACUlJSZmZmZmZmZmZmZmZmampqZmZmampqZmZmZmZmZmZmZmZmZmZmampqampqampqampqZmZmampqbm5uampqZmZmcnJyZmZmYmJiZmZn////MzMykpKTx8fHc3Ny2trb4+Pjv7+++vr5qzdsUAAAAGnRSTlMAB97w45JqTB3R+OvVxbqxnI5xUUU/FBLSJz/VgGEAAAGdSURBVFjDrNIJUsJAEIXhzqxkX8gCLyKo97+jQqGiJJPJpL8D/NU1b2hB3xpdxCJNklTEhTZtT+EiW8d4Etc2ohBdJTFDVt3q44yAkzDRmlyjsEg13kkr4EVY8rEr4a3c0aK9xApyT26Rxkra+ZJDjtXywfF8GQJksw95VAiijjP3KQRSkzcOGYJlw8S+OTbIn7fW2EQ//WdstP83iMRG8u8wJTYr6YEFA/uwsAAD8bt0Ax+X8/kCl+bnQAUPL+OXDzio7xONd298h4u5B4V3b3yDi6Cbzr83nuHU0VXl33s9wam6TSLZepDXWSxb7/65a8YeaiKKGXuIiXrOHtBTy9pDS4a1B0OatQdNBWsPBR1YeziQYO1BUMraQ0oJpp2Cekg+m7NDIwBgEIaimg2y/6QIDBLxBAv0rldKkh9+IL8yfxQ9NsUHm389vhz4+uILVksAFykuo1zotRXhZknbOW44uSXWpp3HCh18dDTj4VHHWx3AOSLQEENjFg2CNKrSME3jPgwkNTLFUFdjZwzGLbr/UC6s+iNTf+RSfzQc7vwbvO3XPQAAAABJRU5ErkJggg==");
  background-size: 40px 40px;
  cursor: pointer;
}
.left:hover {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFAAAABQCAMAAAC5zwKfAAAAaVBMVEUAAADOzs7MzMzNzc3Ozs7Nzc3MzMzPz8/JycnNzc3MzMzMzMzMzMzNzc3MzMzMzMzMzMzNzc3Nzc3MzMzPz8/Kysr////Ly8vMzMwrjfd7rOFAlfG5xNFhouidudiAruCQtdu9xtDAx89I7Ji0AAAAGHRSTlMAB+DTkU7wHRP46+XcxbqxnHFsaEU/ASdoXxwfAAABkklEQVRYw6zUB04DMRCF4XGNt/fNCyGh3P+QoEUgEWe9bt8Bfo09lumAmUfZCl4WRclFK8fZUDympIBFSMUowrr0Gjt0v6yhw001nOqJheSGCoeqwTupOLxwRT5OHbx1J4/xNALooyGZRCDJyME0CNYYx/UJRBC7F3nmiMLPO/NxROJPZzQC0YQhC2uQoLF3LZFEWu8ZidTDQjQS6f+L6ZCssw6c8dCMIwPO6NcAH/fb7Q6X4W/ACh5eLt/e4VAx+jF59y5XuEy0WWu/3gYu9boFl4DeDU7LFuz9e9cPOPXbSrR/7xVumhGRSuvZj1um9exPR2TsQRCZlJ7N0Jy1h5nGrD2MJLP2IKnN2kNLImsPgnjWHjiV2PEW00NJBZ77jOqh+CrOTkwAgGEYBu6/tXcwB1qgUJr6UfiB/Mr8UfjY8MHmX4+LA5cvLrDaArhJcRvlRq+jCA9LPM7pwMkjsQ7tvFbo4qOrGS+Put7qAs4RgYYYGrNoEKRRlYZpGvdhIKmRKYa6GjtjMI7Rfb9ceNcfAyZf1SKWk9zNAAAAAElFTkSuQmCC");
}
.right:hover {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFAAAABQCAMAAAC5zwKfAAAAVFBMVEXMzMz///8rjfd7rOH7+/vi4uLS0tLPz89AlfG5xNHq6ur6+vry8vLw8PDb29vU1NTW1tbv7+/Y2NjR0dH39/dhouidudhClvBiouiQtdu9xtDAx884UkkuAAABj0lEQVRYw8zTyVbDMAyF4ZsiyeAxQ1um939PzCmnUJo4jqNFf+2/o81FV4685ZjE5CRFtp66cihhgXvc1XOgJtCzwUKG/WbQCoqJ3QKSnbDaZKkWDIKqJFSBLqK66NbBwWBDZlgDGRvjIkgjNjfSMugSGkpuCTwKmpLjPOgEjYmbAymhuUQz4IgdjfcgY1f8Hxyws+EWdAY7M+4GjNhd/AsGKBR+QRIoJHQFLUqdT6czarJXcEKh90PuCRVNF3DtwddDtWh/QEGprNWKcgE9ir1tEH0GV0f38Vwv8jdIBuVe6kVDGQyAnhgyyFAUOYM9FMW+AwGaIsFDVfSwUBUtGKoiI0JVjOihKiYIVEWBgapo8jWJnw8KfjVnh1YAwCAMRB2VZf9pQSIRX9AN+kpJcllc2T/Kx2PDB5t/Pb4c+PriC1ZLABcpLqNc6LUV4WZJ2zluOLUl5qadxwodfHQ04+FRx1sdwDUi4BBDYxYNgjSq0jAN4z4NJDUyxVBXY2cLxjG6v1AujPoj4/WJ3NQfBa/zFOqJlLHUAAAAAElFTkSuQmCC");
}
.leftStop {
  width: 40px;
  height: 40px;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFAAAABQCAMAAAC5zwKfAAAAXVBMVEUAAADMzMzOzs7Nzc3Nzc3Ozs7Nzc3Pz8/JycnMzMzNzc3MzMzMzMzMzMzNzc3MzMzMzMzMzMzNzc3MzMzPz8/Kysr////Ly8vMzMzMzMzl5eXY2NjPz8/c3NzU1NS22lFxAAAAGXRSTlMA3wdO0ZJuHRPw+Ovl1cW6sZyOaEU/ASfxsjISKQAAAbxJREFUWMPFmQtywyAMRMEGB+L/L8mmde5/zKbpb1rVGCx1+g6ws5YYIy1qAzcVtjW6PB5LbVpbTE7tJ8sHA4IZ8kzt4DT3Hiv4fj6lmhs1gugxS5ErKmxSFdGSuUYUOlcxHDpE0x0i7Hkk4LdMZhaJ2GAlXYNkGhcoX40d1KuFPGvsQp9X/GnsRP/q0dXYTe0UIWvAoKG9tmBhyXkGk/xHQzyY+O+N6cCmIx8s+NGZhgD6q9MFYrgtyw0hik+DFSJ4ut4JKlYfFsc4vQcIMb7fbzpeLyyo3+7COUFvQZD5IdjH610Rpn+05BKv94ww/rUtOc8fPdwDwx9huAsahj+CUcpx/FGcmjj+KJMqOP4ohbIcfxSrWo4/SqsMzx9ts+b4o2hVcvxRSnXECsRfFMdNwSVVsBQtIUr5phiIKpqog72kHGwLSHq08j+HCaKKE/nBMuvoyBXA82j+4pLKwVKk12jmAak6+oyMIjyPvfiwJD7OSQ+c4iOx9NAuvlZILz7iq5n88ii/3sov4PIRgXyIIR+zyAdB8lGVfJgmH/fJB5Lykal8qCsfO8sH4zS6v2CFSz+f/v9xYe/zxwsS3ufOMgF5ZQAAAABJRU5ErkJggg==");
  background-size: 40px 40px;
}
.rightStop {
  width: 40px;
  height: 40px;
  margin-left: 40px;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFAAAABQBAMAAAB8P++eAAAAMFBMVEXMzMz////l5eXh4eHPz8/Y2Nj7+/vT09Pw8PDq6urb29vR0dHV1dX6+vry8vL39/f/sx2qAAABpklEQVRIx5zRvUrDUBiH8QdSDU1LoRRjC34RcG/FQZwK7eAi1Dswk7jFxUFwCHoB7eIoBQfXXoBCnZ0EwcGljo7egW2JNefkJHnps54f/zO8VNXc78PXbvfp4d7XHlDZzZio8oGfDrf2idX5SoONACV7YIbnI7RKdyZYD0hkh0m4OcSQ85aAtxg70mGNlFoqdHuktOIr8IPUruNwe0RqpZ8Y/CSj53/oBmRk+wu4RmYnC9gkM+sP1skpjOA7OR1HcEhOzgwKfoZwDs/I7XIO+yhdPZKoOIfq+cqe10OvNIMNlCzP2yXRYArXdWiavJjCCUqOZ5qsVNHvV9gzTVpV3DZqHdNkwacOksmQGkgmW2wgmnzhFNHkKhNEkxX6iCaLNBFNWgwRTTqMEU2WCRBN2rQRTRYUqE3uLAXlX/82XgcFAMAgDAMFYAtZiMIFthDQz0kYgzRde0zxeHjg/IW8FLxmvLh8CnxcfK4MAEUKQ4qxxyBVNDPsOT44kDTiODQ5hjXYWRVYPlRnWJBUuVjiWAtdNF1dXYZdr13YvQJ4qciact2TNeUBTQmPdYBZKvwAAAAASUVORK5CYII=");
  background-size: 40px 40px;
}
.right {
  width: 40px;
  height: 40px;
  margin-left: 40px;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFAAAABQCAMAAAC5zwKfAAAAbFBMVEUAAACUlJSZmZmZmZmZmZmZmZmampqZmZmampqZmZmZmZmZmZmZmZmZmZmampqampqampqampqZmZmampqbm5uampqZmZmcnJyZmZmYmJiZmZn////MzMykpKTx8fHc3Ny2trb4+Pjv7+++vr5qzdsUAAAAGnRSTlMAB97w45JqTB3R+OvVxbqxnI5xUUU/FBLSJz/VgGEAAAGeSURBVFjDrNUJbsIwEIXh8U72hZDASym0vf8dixS1pQQcx5nvAL9GM5ZMC/rOmjKRWZpmMimN7XqKJ1yTYCZpnKAYh1rhBVUfVg9nJbykFWtyrcYi3QYnnUQQ6SjErkKwakeL9gorqD35CYOVjHeTQ4HVisGzvhwR8peLPGlE0acX82lE0k9nHHJEyweaEQU2KOa3NtjEzN4zNto/HERhI/X/MBU2q+iOAwN3d2EJBvLv0i18rpfLFSHa3wE1PL7GmzcE0D8jWvh8jsFFSxMJn48xuCin3gFelzG8OP2uNbzO7+HFmm6EAltRielR8xUdETVgLDZElICxmBD1AGexpw6sxY4sWIuWDFiLhkqwFks6grV4JAnWoqQMrMWMUkQVz3gu/W7ODo0AgEEYimo2yP6TIjBIxBMs0LteKUl++IH8yvxR9NgUH2z+9fhy4OuLL1gtAVykuIxyoddWhJslbee44eSWWJt2Hit08NHRjIdHHW91AOeIQEMMjVk0CNKoSsM0jfswkNTIFENdjZ0xGLfo/kO5sOqPTP2RS/3RFZ78GxCH4RQAAAAASUVORK5CYII=");
  background-size: 40px 40px;
  cursor: pointer;
}

.img {
  margin-top: 16px;
  width: 702px;
  height: 294px;
  display: flex;
  display: -webkit-flex;
  overflow: hidden;
  margin-left: 15px;
}

.showimg {
  width: 224px;
  height: 294px;
  margin-right: 15px;
  -webkit-transition-property: -webkit-transform;
  transition-property: transform;
  -webkit-transition-duration: 1s;
  transition-duration: 1s;
  flex: 0 0 224px;
}
.showimg:nth-child(3) {
  width: 224px;
  height: 294px;
  margin-right: 15px;
  -webkit-transition-property: -webkit-transform;
  transition-property: transform;
  -webkit-transition-duration: 1s;
  transition-duration: 1s;
  flex: 0 0 224px;
}

.img1 {
  width: 224px;
  height: 294px;
  cursor: pointer;
}

.title h3 {
  width: 97px;

  font-size: 18px;

  font-family: AdobeHeitiStd-Regular;

  color: rgba(13, 20, 30, 1);

  line-height: 18px;

  margin-top: 131px;

  font-weight: 550;
}

.title h4 {
  width: 164px;

  line-height: 11px;

  font-size: 11px;

  font-family: AdobeHeitiStd-Regular;

  color: rgba(93, 100, 110, 1);

  margin-top: 7px;

  font-weight: 500;
}

.zz {
  width: 100%;

  height: 100%;

  background-color: #000;

  filter: alpha(opacity=70);

  -moz-opacity: 0.7;

  opacity: 0.7;

  position: absolute;

  z-index: 100;
}

.course {
  width: 100%;

  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 1200px) {
  .window {
    background-color: white;
    width: 700px;
    height: 403px;
    text-align: center;
    overflow: hidden;
  }

  .title {
    margin-left: 14.4px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }
  .left {
    width: 24px;
    height: 24px;
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFAAAABQCAMAAAC5zwKfAAAAbFBMVEUAAACUlJSZmZmZmZmZmZmZmZmampqZmZmampqZmZmZmZmZmZmZmZmZmZmampqampqampqampqZmZmampqbm5uampqZmZmcnJyZmZmYmJiZmZn////MzMykpKTx8fHc3Ny2trb4+Pjv7+++vr5qzdsUAAAAGnRSTlMAB97w45JqTB3R+OvVxbqxnI5xUUU/FBLSJz/VgGEAAAGdSURBVFjDrNIJUsJAEIXhzqxkX8gCLyKo97+jQqGiJJPJpL8D/NU1b2hB3xpdxCJNklTEhTZtT+EiW8d4Etc2ohBdJTFDVt3q44yAkzDRmlyjsEg13kkr4EVY8rEr4a3c0aK9xApyT26Rxkra+ZJDjtXywfF8GQJksw95VAiijjP3KQRSkzcOGYJlw8S+OTbIn7fW2EQ//WdstP83iMRG8u8wJTYr6YEFA/uwsAAD8bt0Ax+X8/kCl+bnQAUPL+OXDzio7xONd298h4u5B4V3b3yDi6Cbzr83nuHU0VXl33s9wam6TSLZepDXWSxb7/65a8YeaiKKGXuIiXrOHtBTy9pDS4a1B0OatQdNBWsPBR1YeziQYO1BUMraQ0oJpp2Cekg+m7NDIwBgEIaimg2y/6QIDBLxBAv0rldKkh9+IL8yfxQ9NsUHm389vhz4+uILVksAFykuo1zotRXhZknbOW44uSXWpp3HCh18dDTj4VHHWx3AOSLQEENjFg2CNKrSME3jPgwkNTLFUFdjZwzGLbr/UC6s+iNTf+RSfzQc7vwbvO3XPQAAAABJRU5ErkJggg==");
    background-size: 24px 24px;
    cursor: pointer;
  }
  .left:hover {
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFAAAABQCAMAAAC5zwKfAAAAaVBMVEUAAADOzs7MzMzNzc3Ozs7Nzc3MzMzPz8/JycnNzc3MzMzMzMzMzMzNzc3MzMzMzMzMzMzNzc3Nzc3MzMzPz8/Kysr////Ly8vMzMwrjfd7rOFAlfG5xNFhouidudiAruCQtdu9xtDAx89I7Ji0AAAAGHRSTlMAB+DTkU7wHRP46+XcxbqxnHFsaEU/ASdoXxwfAAABkklEQVRYw6zUB04DMRCF4XGNt/fNCyGh3P+QoEUgEWe9bt8Bfo09lumAmUfZCl4WRclFK8fZUDympIBFSMUowrr0Gjt0v6yhw001nOqJheSGCoeqwTupOLxwRT5OHbx1J4/xNALooyGZRCDJyME0CNYYx/UJRBC7F3nmiMLPO/NxROJPZzQC0YQhC2uQoLF3LZFEWu8ZidTDQjQS6f+L6ZCssw6c8dCMIwPO6NcAH/fb7Q6X4W/ACh5eLt/e4VAx+jF59y5XuEy0WWu/3gYu9boFl4DeDU7LFuz9e9cPOPXbSrR/7xVumhGRSuvZj1um9exPR2TsQRCZlJ7N0Jy1h5nGrD2MJLP2IKnN2kNLImsPgnjWHjiV2PEW00NJBZ77jOqh+CrOTkwAgGEYBu6/tXcwB1qgUJr6UfiB/Mr8UfjY8MHmX4+LA5cvLrDaArhJcRvlRq+jCA9LPM7pwMkjsQ7tvFbo4qOrGS+Put7qAs4RgYYYGrNoEKRRlYZpGvdhIKmRKYa6GjtjMI7Rfb9ceNcfAyZf1SKWk9zNAAAAAElFTkSuQmCC");
  }
  .right:hover {
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFAAAABQCAMAAAC5zwKfAAAAVFBMVEXMzMz///8rjfd7rOH7+/vi4uLS0tLPz89AlfG5xNHq6ur6+vry8vLw8PDb29vU1NTW1tbv7+/Y2NjR0dH39/dhouidudhClvBiouiQtdu9xtDAx884UkkuAAABj0lEQVRYw8zTyVbDMAyF4ZsiyeAxQ1um939PzCmnUJo4jqNFf+2/o81FV4685ZjE5CRFtp66cihhgXvc1XOgJtCzwUKG/WbQCoqJ3QKSnbDaZKkWDIKqJFSBLqK66NbBwWBDZlgDGRvjIkgjNjfSMugSGkpuCTwKmpLjPOgEjYmbAymhuUQz4IgdjfcgY1f8Hxyws+EWdAY7M+4GjNhd/AsGKBR+QRIoJHQFLUqdT6czarJXcEKh90PuCRVNF3DtwddDtWh/QEGprNWKcgE9ir1tEH0GV0f38Vwv8jdIBuVe6kVDGQyAnhgyyFAUOYM9FMW+AwGaIsFDVfSwUBUtGKoiI0JVjOihKiYIVEWBgapo8jWJnw8KfjVnh1YAwCAMRB2VZf9pQSIRX9AN+kpJcllc2T/Kx2PDB5t/Pb4c+PriC1ZLABcpLqNc6LUV4WZJ2zluOLUl5qadxwodfHQ04+FRx1sdwDUi4BBDYxYNgjSq0jAN4z4NJDUyxVBXY2cLxjG6v1AujPoj4/WJ3NQfBa/zFOqJlLHUAAAAAElFTkSuQmCC");
  }
  .leftStop {
    width: 24px;
    height: 24px;
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFAAAABQCAMAAAC5zwKfAAAAXVBMVEUAAADMzMzOzs7Nzc3Nzc3Ozs7Nzc3Pz8/JycnMzMzNzc3MzMzMzMzMzMzNzc3MzMzMzMzMzMzNzc3MzMzPz8/Kysr////Ly8vMzMzMzMzl5eXY2NjPz8/c3NzU1NS22lFxAAAAGXRSTlMA3wdO0ZJuHRPw+Ovl1cW6sZyOaEU/ASfxsjISKQAAAbxJREFUWMPFmQtywyAMRMEGB+L/L8mmde5/zKbpb1rVGCx1+g6ws5YYIy1qAzcVtjW6PB5LbVpbTE7tJ8sHA4IZ8kzt4DT3Hiv4fj6lmhs1gugxS5ErKmxSFdGSuUYUOlcxHDpE0x0i7Hkk4LdMZhaJ2GAlXYNkGhcoX40d1KuFPGvsQp9X/GnsRP/q0dXYTe0UIWvAoKG9tmBhyXkGk/xHQzyY+O+N6cCmIx8s+NGZhgD6q9MFYrgtyw0hik+DFSJ4ut4JKlYfFsc4vQcIMb7fbzpeLyyo3+7COUFvQZD5IdjH610Rpn+05BKv94ww/rUtOc8fPdwDwx9huAsahj+CUcpx/FGcmjj+KJMqOP4ohbIcfxSrWo4/SqsMzx9ts+b4o2hVcvxRSnXECsRfFMdNwSVVsBQtIUr5phiIKpqog72kHGwLSHq08j+HCaKKE/nBMuvoyBXA82j+4pLKwVKk12jmAak6+oyMIjyPvfiwJD7OSQ+c4iOx9NAuvlZILz7iq5n88ii/3sov4PIRgXyIIR+zyAdB8lGVfJgmH/fJB5Lykal8qCsfO8sH4zS6v2CFSz+f/v9xYe/zxwsS3ufOMgF5ZQAAAABJRU5ErkJggg==");
    background-size: 24px 24px;
  }
  .rightStop {
    width: 24px;
    height: 24px;
    margin-left: 24px;
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFAAAABQBAMAAAB8P++eAAAAMFBMVEXMzMz////l5eXh4eHPz8/Y2Nj7+/vT09Pw8PDq6urb29vR0dHV1dX6+vry8vL39/f/sx2qAAABpklEQVRIx5zRvUrDUBiH8QdSDU1LoRRjC34RcG/FQZwK7eAi1Dswk7jFxUFwCHoB7eIoBQfXXoBCnZ0EwcGljo7egW2JNefkJHnps54f/zO8VNXc78PXbvfp4d7XHlDZzZio8oGfDrf2idX5SoONACV7YIbnI7RKdyZYD0hkh0m4OcSQ85aAtxg70mGNlFoqdHuktOIr8IPUruNwe0RqpZ8Y/CSj53/oBmRk+wu4RmYnC9gkM+sP1skpjOA7OR1HcEhOzgwKfoZwDs/I7XIO+yhdPZKoOIfq+cqe10OvNIMNlCzP2yXRYArXdWiavJjCCUqOZ5qsVNHvV9gzTVpV3DZqHdNkwacOksmQGkgmW2wgmnzhFNHkKhNEkxX6iCaLNBFNWgwRTTqMEU2WCRBN2rQRTRYUqE3uLAXlX/82XgcFAMAgDAMFYAtZiMIFthDQz0kYgzRde0zxeHjg/IW8FLxmvLh8CnxcfK4MAEUKQ4qxxyBVNDPsOT44kDTiODQ5hjXYWRVYPlRnWJBUuVjiWAtdNF1dXYZdr13YvQJ4qciact2TNeUBTQmPdYBZKvwAAAAASUVORK5CYII=");
    background-size: 24px 24px;
  }
  .right {
    width: 24px;
    height: 24px;
    margin-left: 24px;
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFAAAABQCAMAAAC5zwKfAAAAbFBMVEUAAACUlJSZmZmZmZmZmZmZmZmampqZmZmampqZmZmZmZmZmZmZmZmZmZmampqampqampqampqZmZmampqbm5uampqZmZmcnJyZmZmYmJiZmZn////MzMykpKTx8fHc3Ny2trb4+Pjv7+++vr5qzdsUAAAAGnRSTlMAB97w45JqTB3R+OvVxbqxnI5xUUU/FBLSJz/VgGEAAAGeSURBVFjDrNUJbsIwEIXh8U72hZDASym0vf8dixS1pQQcx5nvAL9GM5ZMC/rOmjKRWZpmMimN7XqKJ1yTYCZpnKAYh1rhBVUfVg9nJbykFWtyrcYi3QYnnUQQ6SjErkKwakeL9gorqD35CYOVjHeTQ4HVisGzvhwR8peLPGlE0acX82lE0k9nHHJEyweaEQU2KOa3NtjEzN4zNto/HERhI/X/MBU2q+iOAwN3d2EJBvLv0i18rpfLFSHa3wE1PL7GmzcE0D8jWvh8jsFFSxMJn48xuCin3gFelzG8OP2uNbzO7+HFmm6EAltRielR8xUdETVgLDZElICxmBD1AGexpw6sxY4sWIuWDFiLhkqwFks6grV4JAnWoqQMrMWMUkQVz3gu/W7ODo0AgEEYimo2yP6TIjBIxBMs0LteKUl++IH8yvxR9NgUH2z+9fhy4OuLL1gtAVykuIxyoddWhJslbee44eSWWJt2Hit08NHRjIdHHW91AOeIQEMMjVk0CNKoSsM0jfswkNTIFENdjZ0xGLfo/kO5sOqPTP2RS/3RFZ78GxCH4RQAAAAASUVORK5CYII=");
    background-size: 24px 24px;
    cursor: pointer;
  }

  .img {
    margin-top: 12px;
    width: 686px;
    height: 276px;
    display: flex;
    display: -webkit-flex;
    overflow: hidden;
    padding-left: 14px;
    box-sizing: border-box;
  }

  .showimg {
    width: 210px;
    height: 276px;
    margin-right: 14px;
    -webkit-transition-property: -webkit-transform;
    transition-property: transform;
    -webkit-transition-duration: 1s;
    transition-duration: 1s;
    flex: 0 0 210px;
  }
  .showimg:nth-child(3) {
    width: 210px;
    height: 276px;
    margin-right: 14px;
    -webkit-transition-property: -webkit-transform;
    transition-property: transform;
    -webkit-transition-duration: 1s;
    transition-duration: 1s;
    flex: 0 0 210px;
  }

  .img1 {
    width: 210px;
    height: 276px;
    cursor: pointer;
  }

  .title h3 {
    width: 91.2px;

    font-size: 16px;

    font-family: AdobeHeitiStd-Regular;

    color: rgba(13, 20, 30, 1);

    line-height: 16px;

    margin-top: 14px;

    font-weight: 550;
  }

  .title h4 {
    width: 150px;

    line-height: 10px;

    font-size: 10px;

    font-family: AdobeHeitiStd-Regular;

    color: rgba(93, 100, 110, 1);

    margin-top: 6px;

    font-weight: 500;
  }
}
</style>