<template>
  <div class="videoHeader">
    <div class="headLeft"></div>
    <div class="headRight">
      <button class="changeCourseBtn" @click.stop="changeCourse">
        <span class="changeCourseIcon"></span>
        <span>切换课程</span>
      </button>
      <div class="infoBox" @mouseover="isOpenInfo = true" @mouseout="isOpenInfo = false">
        <div class="wh100" @click.stop="optnInfo">
          <div class="infoItem1">
            <img :src="userImg" class="userImg">
            <span class="userVipIcon" v-if="isVIP == true">VIP</span>
          </div>
          <div class="infoItem2">
            <p class="userName">{{userName}}</p>
            <p class="userNum">学号：{{userNum}}</p>
          </div>
          <div class="infoItem3">
            <div class="openInfo" :class="{'rotate': isOpenInfo }"></div>
          </div>
        </div>
        <transition name="fade">
          <div class="infoOpenBox" v-if="isOpenInfo == true">
            <div class="openItem" @click.stop="editPassWord"><span class="editpwIcon"></span><span>修改密码</span></div>
            <div class="openItem" @click.stop="returnHome"><span class="returnHomeIcon"></span><span>返回首页</span></div>
            <div class="openItem" @click.stop="logOut"><span class="logOutIcon"></span><span>退出登录</span></div>
          </div>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'videoHeader',
  data () {
    return {
      isOpenInfo: false, // 是否展开信息
      userImg: null,
      userName: "", //用户名
      userNum: "", //学号
      isVIP: false,
    }
  },
  created() {
    this.setUserInfo();
  },
  methods: {
    //设置用户信息
    setUserInfo() {
      this.userImg = localStorage.getItem("avatarUrl") || null;
      this.userName = localStorage.getItem("childNn") || "";
      this.userNum = localStorage.getItem("number") || "";
      this.isVIP = localStorage.getItem("vipType") == 2 ? true : false;
    },
    //展开用户信息
    optnInfo() {
      this.isOpenInfo = !this.isOpenInfo;
    },
    //退出登录
    logOut() {
      let that = this;
      
      let key = localStorage.getItem("key") || "";
      this.axios.post('/api/logout',{key : key}).then((res)=>{
        if (res.data.code == 20000) {
          localStorage.removeItem("key");
          setTimeout(() => {
            that.$router.push('/login');
          }, 500);
        } else {
          if (res.data.code == 40003) {
            that.$router.push({ path: "/login" });
            localStorage.removeItem("key");
            return;
          }
          alert(res.data.errMsg)
        }
      }).catch((error)=>{
        console.log(error);
      })
    },
    //选择课程
    changeCourse() {
      this.$emit("listenChooseCourse");
      this.isOpenInfo = false;
    },
    //修改密码
    editPassWord() {
      this.$emit("listenEditPassWordBox");
      this.isOpenInfo = false;
    },
    //返回首页
    returnHome() {
      this.$router.push({ path: '/' });
    }
  }
}
</script>
<style scoped>
.videoHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
}
.headLeft {
  margin-left: 60px;
  width: 140px;
  height: 34px;
  background-image: url('https://xcx.alicdn2.hexiaoxiang.com/web_static/new_hx_web/logo_v.png');
  background-size: 140px 34px;
}
.headRight {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 497px;
  height: 60px;
}
.changeCourseBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 13px 22px;
  font-size: 18px;
  color: #fff;
  border: none;
  background-color: #2B8DF7;
  outline: none;
  border-radius: 30px;
  cursor: pointer;
}
.changeCourseBtn:hover {
  background:rgba(43,141,247,0.8);
}
.changeCourseIcon {
  margin-right: 8px;
  width: 24px;
  height: 24px;
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAMAAABg3Am1AAAAgVBMVEUAAAD///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////9d3yJTAAAAKnRSTlMAKq4F/d0iXgsC6rClkof48e4x9OPDvHx0EubRyJuLd2xiRTUoHJ6BU1KnsExEAAABH0lEQVRIx+2V2a6CMBBAx1JvKcq+u163u8z/f6CBYbG20vBqPA8m45xDKAkB3pTvjONAumTCNVkSeo74TCY03Qmi0O8u5KGOL5/8VfPvmoYNGggMPvK6nSpTwM+qTyy6Q2+XPd5w/N2Dz3AING5lhESi+RRoXDNaHkd/OoCEbmsPRIC2ANZIz5Dw7MFfu2RAxPYgUYKyGfJiKlgoAVQxZy6bEYB0AWYEDZ/gE6iBI2cFLuNxNSMo8ua3VILLVEB4SnCyB5Hynuf2wAfiQOPJFjAHCEFzKIyB7kPNkShE8tVTj4Hq0yF0+J6Eq8GHW4oGfqHBDdthRX7PPzcEG9r5na8iQj3waCWDlP/0/shl9/pDJR0wcfa3MR/16ODAW3IHCdNpSGCMmUYAAAAASUVORK5CYII=');
  background-size: 24px 24px;
}
.infoBox {
  position: relative;
  margin: 0 20px;
  width: 287px;
  height: 60px;
  background-color: #3D3D3D;
  border-radius: 4px;
  color: #fff;
  cursor: pointer;
}
.infoItem1 {
  margin-right: 8px;
  position: relative;
  flex: 0 0 55px;
  height: 40px;
  text-align: left;
}
.infoItem2 {
  flex: 0 0 140px;
  width: 140px;
  height: 40px;
  text-align: left;
}
.infoItem3 {
  flex: 0 0 10px;
  height: 5px;
  text-align: center;
}
.infoOpenBox {
  position: absolute;
  bottom: -147px;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 287px;
  height: 147px;
  background-color: #3D3D3D;
  border-radius:4px;
  z-index: 100;
}
.userImg {
  display: inline-block;
  width: 40px;
  height: 40px;
  border-radius: 20px;
  background-color: #eee;
}
.openInfo {
  width: 10px;
  height: 5px;
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAMAAACDi47UAAAAXVBMVEUAAAD///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////9RKvvlAAAAH3RSTlMAzAXJtEsnxMG7pJ2LfGtXNRYSCwGXgGdhXVNGOyMcK/A5/AAAAGNJREFUCNd1jlcSgCAMRF0EC4gI2Nv9jynYUGfMR/Kymexs9FODfm5r7ztDngStysHcsA2y+dJUjMJ6IC3iMdpJAmV93ruDjUA6BSfmvkhCQb178M/AUwjzzrVQQJJvWs3VzRsnowLIKT0XVgAAAABJRU5ErkJggg==');
  background-size: 10px 5px;
}
.userVipIcon {
  position: absolute;
  right: 0;
  bottom: 0;
  padding: 4px 8px;
  box-sizing: border-box;
  width: 34px;
  height: 20px;
  background-color: #FFBB68;
  font-size: 12px;
  border-radius:4px 8px 4px 8px;
}
.openItem {
  flex: 1;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  color: #fff;
  cursor: pointer;
}
.openItem:hover {
  background-color: #575757;
}
.editpwIcon {
  margin-right: 20px;
  width: 11px;
  height: 13px;
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAaCAMAAACEqFxyAAAAPFBMVEUAAAD////////////////////////////////////////////////////////////////////////////YSWgTAAAAE3RSTlMA8g3SsNiTj4JTUUErJhUGvHk7Rk+5SAAAAKZJREFUKM+tkt0OwyAIRkGsWrX2h/d/18G6alm2Xe1cfTkhIER40RIhUmpgmJGf4Gwsd26+Se2UncuT1I8+SazT4MSnrok5nykzU9fSw53JSZeuZZKJP/TikQ3oF7FdDnABzx/w+ooDDIeUm9Fj7H/0WFwYByDmYnXRc0V9pUE2iVCl0363u4gKEHSrch226NZB0rrxG9sK6oO1Qa1SI10fgmKF7zwAJ2oQJR1qxx0AAAAASUVORK5CYII=');
  background-size: 11px 13px;
}
.returnHomeIcon {
  margin-right: 20px;
  width: 13px;
  height: 12px;
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAYCAMAAADTXB33AAAAaVBMVEUAAAD////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////8G612AAAAInRSTlMAwCH311AJ+0i8DQbyx7ZC7OezV9+aTTuppW1oW0w1MxoZfuCXagAAAKRJREFUKM/V0UkWgyAQRdFCLEDFvkvf/P0vMmBixEM2kDfhc+6waKsX1ooLxRVHLB2KSDoATQOgK2LRJ6KzBioViqqc9H6xs0zFspgMTWWA5PU3ORMqFp/xlvuVCydXor2V+UcM7arfVsXizAIZtZAT8xzCzGwsGqrHWwKIkASQ3Mfaz1/knv+gJ5CGlAKPdQ9Am35rgYHWWGOXDk9Uyg1kacj3Auk8E/4iFtEKAAAAAElFTkSuQmCC');
  background-size: 13px 12px;
}
.logOutIcon {
  margin-right: 20px;
  width: 9px;
  height: 12px;
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAcCAMAAABF0y+mAAAAeFBMVEUAAAD////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////GqOSsAAAAJ3RSTlMAf5m/QPlm2c1UJO4TCwXiu59uYDo3MCgZyKuhkYFzQ/DSjYdkWUyN0uJLAAAAxElEQVQoz52S1w7CMAxFOwhpSweU7sm+//+HFDocR2olOE+Jj3WlxDYGwr4zF+LEUEkkGE6uOAGNgze7UA43i2JrAdiz7MdOIh3sXOgAy2AcAXc6moDJ5Qk4/yebrdi8rAImdTakK5twVV4B21+TLQDp6TJ/7L84AA4pl4Vg0+HSg8pRi3Ut4l78/M40cvxVaQFPki2XNU0lBiouS+A1HTNAjBOibuErWwFpL0RQ96aIoOEElOOf2K9dbh9HBNluIQup/gbatB9W3djgTwAAAABJRU5ErkJggg==');
  background-size: 9px 12px;
}
.userName {
  width: 140px;
  height: 20px;
  overflow: hidden;
  text-overflow:ellipsis;
  white-space: nowrap;
  font-size: 18px;
  color: #fff;
}
.userNum {
  width: 140px;
  overflow: hidden;
  text-overflow:ellipsis;
  white-space: nowrap;
  font-size: 14px;
  color: rgba(255,255,255,0.6);
}
.rotate{
    transform-origin:center center;
    transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transition: transform 0.2s; 
    -moz-transition: -moz-transform 0.2s; 
    -moz-transition: -moz-transform 0.2s; 
    -o-transition: -o-transform 0.2s; 
    -ms-transition: -ms-transform 0.2s; 
}
.wh100 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
