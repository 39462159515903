import axios from 'axios'
import router from '../router'

const URL_LIST = {
  "development": "https://apitest.hexiaoxiang.com",
  "production": "https://api.hexiaoxiang.com",
}

const urlHref = window.location.href
const NODE_ENV = urlHref.includes('wwwtest') || urlHref.includes('localhost') ? "development" : "production"
const request = axios.create({
  baseURL: URL_LIST[NODE_ENV],
  timeout: 5000,
});

request.interceptors.request.use((config) => {
  let key = localStorage.getItem("key");
  if (!key) {
    router.push('/login')
    return Promise.reject()
  }
  const { headers } = config
  headers.authorization = key
  return config
}, (err) => {
  return Promise.reject(err)
})

request.interceptors.response.use(async (responese) => {
  const { data } = responese
  switch (data.code) {
    case 20000:
      return responese.data
    case 200:
      return responese.data  
    case 40003:
      localStorage.removeItem("key");
      router.push('/login')
      return Promise.reject()
    default:
      return Promise.reject()
  }
})

export default request