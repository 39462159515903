<template>
  <div class="videoSideBar">
    <div class="classInfo" v-bind:class="hideSide == true ? 'height0' : ''">
      <div class="classImgBox">
        <img :src="classImg" class="classImg" />
      </div>
      <div class="classDes">
        <div class="className">
          <p class="nameInfo">{{className}}</p>
        </div>
      </div>
      <div class="hideSideBar" @click.stop="tiggleSideBar">
        <div class="packImg" v-bind:class="hideSide == true ? 'rotate1' : ''"></div>
      </div>
    </div>
    <div class="courseList" id="courseList" v-bind:class="hideSide == true ? 'top0' : ''">
      <div class="courseItem" v-for="(item, index) in courseList" v-bind:key="index">
        <div class="notNew" @click.stop="showLessonList(item,index)">
          <div class="courseNum">第{{index+1}}节</div>
          <div class="courseName">{{item.title}}</div>
          <div :class="{'courseFinish': item.state === 3, 'notUnlock': item.state === 1 }"></div>
        </div>

        <div v-if="currentIndex === index">
          <div
            v-for="(itemb,indexb) in lessonList"
            v-bind:key="itemb.module_id"
            @click.stop="videoPlay(itemb, (itemb.is_finish === false && lessonList[indexb-1] && lessonList[indexb-1].is_finish ===false) || item.state === 1, indexb)"
            :class="{'lessonName':true,'courseSelect': indexb == currentModuleIndex}"
          >
            <div />
            <div>{{itemb.title}}</div>
            <div
              :class="{'courseFinish': itemb.is_finish === true,
                 'notUnlock': (itemb.is_finish === false && lessonList[indexb-1] && lessonList[indexb-1].is_finish ===false) || item.state === 1,
                 }"
            ></div>
          </div>
          <div
            class="uploadWork"
            v-if="item.payload.hand_work_type === 1 && lessonList.length > 0"
            @click.stop="listenUpLoad(!lessonList[lessonList.length - 1].is_finish == true)"
          >
            <div />上传作业
            <div :class="{'notUnlock': !lessonList[lessonList.length - 1].is_finish == true}" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueQr from "vue-qr";
import {
  getLessonDetail,
  getModuleDetail,
  initModelStatus
} from "../api/course";
export default {
  components: {
    VueQr
  },
  name: "videoSideBar",
  props: ["courseId", "refresh"],
  data() {
    return {
      classImg: null, //课程封面
      isOpenInfo: false,
      showTeacherList: false,
      className: "班级名称", //班级名
      expireWords: 0, //vip过期时间
      courseList: [], //课程列表
      lessonList: [], // 课节列表
      currentIndex: 0, // 当前选中模块
      hideSide: false,
      currentModuleIndex: 0
    };
  },
  created() {
    this.getLessons();
  },
  watch: {
    courseId: function(val) {
      this.courseId = val;
      this.getLessons();
      this.isOpenInfo = false;
      this.showTeacherList = false;
      localStorage.setItem("courseId", val);
    },
    refresh: function(val) {
      this.getLessons("fresh");
      this.isOpenInfo = false;
      this.showTeacherList = false;
    }
  },
  mounted() {},
  methods: {
    //显示隐藏侧栏
    tiggleSideBar() {
      this.hideSide = !this.hideSide;
    },
    //向父组件传videoUrl
    postVideoUrl(url) {
      if (url) {
        this.$emit("listenSetUrl", url);
      } else {
        alert("视频地址不存在");
      }
    },

    // 剩余天数
    surplusTime: function (time) {
      if (time < getDate()) return 0
      var surplusTime = (time - getDate()) / 1000 / 60 / 60 / 24
      return surplusTime > 0 ? Math.ceil(surplusTime) : 0
    },

    // 视频播放完成处理
    handleVideoEnd(){
      const course_id = +localStorage.getItem("courseId");
      const lesson_id = +localStorage.getItem("lessonId");
      getModuleDetail({ course_id, lesson_id }).then(({ data = {} }) => {
        const { lesson, items } = data;
        localStorage.setItem("lessonId", lesson.lesson_id);
        this.lessonList = items.filter(({ module_type }) => module_type === 1);
        const isHaveNextCourse = this.currentModuleIndex < this.lessonList.length - 1
        if(isHaveNextCourse){
          this.currentModuleIndex = this.currentModuleIndex+1
          this.videoPlay(this.lessonList[this.currentModuleIndex]);
          return
        }
        // 打开上传作业二维码
        this.listenUpLoad(false)
      });
    },

    // 点击上传作业
    listenUpLoad(isFinishLesson) {
      if (isFinishLesson) {
        this.$message("请先完成该课程，再提交作业");
        return;
      }
      this.listenUpLoaad();
    },

    // 获取新课程信息
    getLessons(refresh) {
      let params = {};
      let that = this;
      params.course_id = localStorage.getItem("courseId");
      getLessonDetail(params)
        .then(({ data = {}, code }) => {
          if (code == 20000) {
            const { course, items } = data;
            let lessons = items;
            that.className = course.title; //班级名
            that.classImg = course.vertical_covers[0]; //课程封面
            let course_id = course.courseId;
            that.courseList = items;
            if (refresh) {
              this.showLessonList(
                items[that.currentIndex],
                that.currentIndex,
                "refresh"
              );
              return;
            }
            const nowStudyIndex = items.findIndex(item => item.state === 2);
            if (nowStudyIndex === -1) {
              this.showLessonList(items[0], 0);
              that.currentIndex = 0;
            } else {
              this.showLessonList(items[nowStudyIndex], 0);
              that.currentIndex = nowStudyIndex;
            }
          }
        })
        .catch(error => {
          console.log(error);
        });
    },

    // 播放模块视屏
    videoPlay(item = {}, notPlay) {
      if (notPlay) {
        this.$message("请先完成上个模块的学习哦～");
        return;
      }
      const { media_urls, module_id } = item;
      this.lessonList.forEach((val, index) => {
        if (val.module_id === module_id) {
          this.currentModuleIndex = index;
        }
      });
      localStorage.setItem("moduleId", module_id);
      this.postVideoUrl(media_urls[0].play_url_HD || media_urls[1].play_url_HD );
    },

    // 获取模块信息
    showLessonList(item, index, refresh) {
      let that = this;
      const { course_id, lesson_id, state } = item;
      if (state === 1) {
        this.$message("请先完成上节课程内容");
        return;
      }
      this.currentIndex = index;
      this.lessonList = [];
      getModuleDetail({ course_id, lesson_id }).then(({ data = {} }) => {
        const { lesson, items, tasks } = data;
        localStorage.setItem("lessonId", lesson.lesson_id);
        this.lessonList = items.filter(({ module_type }) => module_type === 1);
        if (refresh) {
          this.videoPlay(this.lessonList[that.currentModuleIndex]);
          return;
        }
        this.videoPlay(this.lessonList[0]);
      });
    },

    //打开上传作业弹窗
    listenUpLoaad() {
      const isHaveWork = !(this.courseList[this.currentIndex].payload.hand_work_type === 1)
      this.$emit("listenUpLoaad",isHaveWork);
    }
  }
};
</script>

<style scoped>
.videoSideBar {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: #000;
}
.classInfo {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 327px;
  height: 200px;
  background-color: #1d1d1d;
}
.classImg {
  flex: 0 0 120px;
  width: 120px;
  height: 160px;
  box-sizing: border-box;
  border: 2px solid rgba(255, 255, 255, 0.12);
  border-radius: 10px;
}
.className {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  width: 157px;
  height: 90px;
}
.vipInfo {
  width: 157px;
  height: 16px;
  font-size: 16px;
  color: rgba(255,255,255,0.6);
  text-align: left;
}
.teacherList {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 137px;
  height: 28px;
  background: rgba(61, 61, 61, 1);
  border-radius: 4px;
  color: rgba(255, 255, 255, 0.8);
  line-height: 28px;
  font-size: 14px;
  cursor: pointer;
}
.uploadWork {
  background: #2b8df7;
  color: #fff;
  width: 287px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: auto;
  min-height: 52px;
  border-radius: 4px;
  margin-bottom: 10px;
  overflow: hidden;
  cursor: pointer;
}
.nameInfo {
  width: 157px;
  height: 60px;
  line-height: 30px;
  font-size: 22px;
  color: #fff;
  text-align: left;
}
.openInfo {
  margin-left: 10px;
  width: 8px;
  height: 3px;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAMAAACDi47UAAAAXVBMVEUAAAD///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////9RKvvlAAAAH3RSTlMAzAXJtEsnxMG7pJ2LfGtXNRYSCwGXgGdhXVNGOyMcK/A5/AAAAGNJREFUCNd1jlcSgCAMRF0EC4gI2Nv9jynYUGfMR/Kymexs9FODfm5r7ztDngStysHcsA2y+dJUjMJ6IC3iMdpJAmV93ruDjUA6BSfmvkhCQb178M/AUwjzzrVQQJJvWs3VzRsnowLIKT0XVgAAAABJRU5ErkJggg==");
  background-size: 8px 3px;
}
.rotate {
  transform-origin: center center;
  transform: rotate(180deg);
  transition: transform 0.2s;
}
.rotate1 {
  transform-origin: center center;
  transform: rotate(270deg) !important;
  transition: transform 0.2s !important;
}
.tList {
  padding: 20px;
  box-sizing: border-box;
  width: 100%;
  height: auto;
  background: rgba(29, 29, 29, 1);
  border-radius: 4px;
}
.tItem {
  margin-bottom: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 50px;
}
.tItem:last-child {
  margin-bottom: 0;
}
.itemImg {
  flex: 0 0 50px;
  width: 50px;
  height: 50px;
  border-radius: 25px;
  background-color: #eee;
  overflow: hidden;
}
.itemImg img {
  width: 50px;
  height: 50px;
  border-radius: 25px;
}
.courseList {
  position: absolute;
  top: 201px;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 10px 20px;
  box-sizing: border-box;
  width: 327px;
  height: auto;
  background-color: #1d1d1d;
  overflow: hidden;
  overflow-y: auto;
}

.courseList::-webkit-scrollbar {
  box-sizing: border-box;
  padding: 4px;
  width: 16px;
  height: 16px;
  background-color: rgba(244, 245, 247, 0.2);
}

/*定义滚动条轨道 内阴影+圆角*/
.courseList::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: rgba(244, 245, 247, 0.2);
}

/*定义滑块 内阴影+圆角*/
.courseList::-webkit-scrollbar-thumb {
  width: 8px;
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: rgba(0, 0, 0, 0.4);
}

.courseItem {
  margin-bottom: 10px;
  width: 287px;
  height: auto;
  min-height: 52px;
  border-radius: 4px;
  overflow: hidden;
}
.lessonName {
  background: rgba(100, 100, 110, 0.2);
  color: #fff;
  width: 287px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: auto;
  min-height: 52px;
  border-radius: 4px;
  margin-bottom: 10px;
  overflow: hidden;
  cursor: pointer;
}
.notNew {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 10px;
  box-sizing: border-box;
  width: 287px;
  height: 52px;
  background-color: #3d3d3d;
  box-shadow: 0px 2px 4px 0px rgba(93, 100, 110, 0.1);
  cursor: pointer;
}
.notNew:hover {
  background-color: rgba(61, 61, 61, 0.8);
}
.isNew {
  box-sizing: border-box;
  width: 287px;
  height: 92px;
  background-color: #3d3d3d;
  border: 1px solid rgba(43, 141, 247, 1);
  box-shadow: 0px 2px 4px 0px rgba(93, 100, 110, 0.1);
  background-color: rgba(43, 141, 247, 1);
  cursor: pointer;
}
.isNew:hover {
  background-color: rgba(43, 141, 247, 0.8);
}
.isNewCourseInfo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 10px;
  box-sizing: border-box;
  width: 285px;
  height: 50px;
  border-radius: 4px 4px 0 0;
  border: none;
  background-color: #3d3d3d;
}
.uploadCourse {
  width: 285px;
  height: 40px;
  background-color: rgba(43, 141, 247, 1);
  border-radius: 0 0 4px 4px;
  font-size: 16px;
  color: #fff;
  line-height: 40px;
  text-align: center;
}
.courseItem:last-child {
  margin-bottom: 0;
}
.courseNum {
  flex: 0 0 60px;
  width: 60px;
  height: 16px;
  font-size: 16px;
  text-align: left;
  color: #fff;
}
.isNewCourseNum {
  flex: 0 0 27px;
  width: 27px;
  height: 16px;
  font-size: 16px;
  text-align: left;
  color: #fff;
}
.courseName {
  flex: 0 0 190px;
  width: 190px;
  height: auto;
  line-height: 20px;
  font-size: 20px;
  text-align: left;
  color: #fff;
}
.isNewCourseName {
  flex: 0 0 190px;
  width: 190px;
  height: auto;
  line-height: 20px;
  font-size: 20px;
  text-align: left;
  color: #fff;
}
.isNewCourseType {
  flex: 0 0 18px;
  height: 18px;
  text-align: center;
}
.courseType {
  flex: 0 0 18px;
  height: 18px;
  text-align: center;
}
.courseFinish {
  width: 18px;
  height: 18px;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAkCAMAAADW3miqAAAAYFBMVEX///8AAAD///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////9oJYrRAAAAH3RSTlOAAGavbPt9dmJTTTQtIxEKhMay7tbPq5iL5N698qSjq89UxwAAAP5JREFUOMuV1OmOwjAMBGAT0jal6X3Asoff/y13iKC4lUNgflXVJ2WkxKaDSFOVpsjzwpRVI/8L5B2JOK+g2tIutt6jEyk5bVBrSI1pn6i1FIltV2QoGhOQ7KP3Cqiml6kDshRLF2rdkI+aY9YT4oFczPTMCyHuQE3sqIl5CF8glW4uX6sBKVVz/mE+3r9BjGaWTBiQQjHfLA1ITiF//WWtPG8NyB1deTw/K8NI9DhuYc6GUHncG5BH8YGZ545+M+itASll23FSDEglrgFRDIi4llk3IPKCJ9W47VPproohv390nTILbz/f9CCkR+r94UyPeXphpFfPp0ssvQ7/AWQ0DfaAZFZsAAAAAElFTkSuQmCC");
  background-size: 18px 18px;
  background-repeat: no-repeat;
}
.height0 {
  padding: 0;
  height: 0;
}
.top0 {
  top: 0;
}
.hideSideBar {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 50%;
  bottom: -20px;
  transform: translateX(-50%);
  width: 50px;
  height: 20px;
  background-color: #111;
  cursor: pointer;
  border-radius: 0 0 80px 80px;
  z-index: 90;
}
.packImg {
  width: 16px;
  height: 20px;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAMAAABg3Am1AAAAS1BMVEUAAABvb29vb29xcXFvb29vb29vb29wcHBvb29wcHBvb29zc3Nvb29vb29vb29vb29wcHBwcHBvb29vb29wcHBvb29tbW1xcXFwcHB5njg2AAAAGHRSTlMACMUi6K5l5F6/px/XspJsU/Dec0ZBFU1lfuL6AAAAiElEQVRIx+XW2wqAIBAEUMvu2d1q//9Lo0dZGpFCSfdxOQOCyChCTTYuw2GuTqX0s2+JaDRWDREVGfJUm/6eHXnamFfQl8wXMhVfQZ//xNeuvke+8+inkF4MzM/QS+ZFDjwOvDoSvoS4EpJZ2/NJMNF8nmijSlhKkSdWa+3ai93p6zCvSgsfcwHzzCzP0kNNGAAAAABJRU5ErkJggg==");
  background-size: 16px 20px;
  background-repeat: no-repeat;
  transform: rotate(90deg);
}
.courseFinish {
  width: 18px;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAkCAMAAADW3miqAAAAYFBMVEX///8AAAD///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////9oJYrRAAAAH3RSTlOAAGavbPt9dmJTTTQtIxEKhMay7tbPq5iL5N698qSjq89UxwAAAP5JREFUOMuV1OmOwjAMBGAT0jal6X3Asoff/y13iKC4lUNgflXVJ2WkxKaDSFOVpsjzwpRVI/8L5B2JOK+g2tIutt6jEyk5bVBrSI1pn6i1FIltV2QoGhOQ7KP3Cqiml6kDshRLF2rdkI+aY9YT4oFczPTMCyHuQE3sqIl5CF8glW4uX6sBKVVz/mE+3r9BjGaWTBiQQjHfLA1ITiF//WWtPG8NyB1deTw/K8NI9DhuYc6GUHncG5BH8YGZ545+M+itASll23FSDEglrgFRDIi4llk3IPKCJ9W47VPproohv390nTILbz/f9CCkR+r94UyPeXphpFfPp0ssvQ7/AWQ0DfaAZFZsAAAAAElFTkSuQmCC");
  background-size: 18px 18px;
  background-repeat: no-repeat;
}
.notUnlock {
  width: 15px;
  height: 15px;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAkCAMAAACpD3pbAAAAS1BMVEX///8AAAD///////////////////////////////////////////////////////////////////////////////////////////+bUGJYAAAAGXRSTlOAAEN2JkoPCHpoPwNjVGoqPB5wXFlQMzAY45asjwAAANtJREFUOMvF1MmWgyAURdF3eSAIYpO2/v9LC0xoTAmzWjmzuzbKSAk5/bhdDM936ZDL7Famd7P8w3qiKvHBeqZD6sg3ivHdiquh2KNm+XqlQ2i47id1xfvFT7zzcdnCQ9weKXcJcyz8jPyDnI1b75wmo7TtxzOrsOZk6TJ54PEbPKxiDItFSUX2Yh1Am6FmZqOR2gX810wfuaMBp44GVB0NKDsa0HFb2RFsmy0ImlvKOjCWFi+IDHGuAi+GP1OPxBBnzxbGwkfk5eP7tlyh1UicclJNbAxPqvp7/AKShQZCmNcxgAAAAABJRU5ErkJggg==");
  background-size: 15px 18px;
  background-repeat: no-repeat;
}
.courseSelect {
  border: 1px solid blue;
  box-sizing: border-box;
}
</style>
