<template>
  <div class="videoSideBar">
    <div class="classInfo"  v-bind:class="hideSide == true ? 'height0' : ''" >
      <div class="classImgBox">
        <img :src="classImg" class="classImg">
      </div>
      <div class="classDes">
        <div class="className">
          <p class="nameInfo">{{className}}</p>
          <span class="vipInfo">{{expireWords}}</span>
        </div>
        <div class="teacherList" @click.stop="optnInfo">
          <span>查看班级信息</span><div class="openInfo" :class="{'rotate': isOpenInfo }"></div>
        </div>
      </div>
      <div class="hideSideBar" @click.stop="tiggleSideBar" >
        <div class="packImg" v-bind:class="hideSide == true ? 'rotate1' : ''" ></div>
      </div>
    </div>
    <div class="courseList" id="courseList"  v-bind:class="hideSide == true ? 'top0' : ''"  >
      <div class="courseItem" v-for="(item, index) in courseList" v-bind:key="index" >
        <div class="notNew" v-if="item.openUpload == false"  @click.stop="showOpenUpLoad(item,index)">
          <div class="courseNum" v-if="index < 9">{{'0'+(index+1)}}</div>
          <div class="courseNum" v-else>{{index+1}}</div>
          <div class="courseName">{{item.name}}</div>
          <div class="courseType " :class="{'courseFinish': item.type==1, 'notUnlock': item.type==3}"></div>
        </div>
        <div class="isNew" v-if="item.openUpload == true"  @click.stop="showOpenUpLoad(item,index)">
          <div class="isNewCourseInfo">
            <div class="isNewCourseNum" v-if="index < 9">{{'0'+(index+1)}}</div>
            <div class="isNewCourseNum" v-else>{{index+1}}</div>
            <div class="isNewCourseName">{{item.name}}</div>
            <div class="isNewCourseType " :class="{'courseFinish': item.type==1, 'notUnlock': item.type==3}"></div>
          </div>
          <div class="uploadCourse" @click.stop="listenUpLoaad">
            上传作业
          </div>
        </div>
      </div>
    </div>

    <!-- 班级老师详情 -->
    <div class="teacherDes" v-if="showTeacherList">
      <div class="tList" v-if="teacherList.length > 0">
        <div class="tItem" v-for="(item, index) in teacherList" v-bind:key="index">
          <div class="itemImg">
            <img :src="item.img">
          </div>
          <div class="itemDes">
            <p class="teacherName">{{item.name}}</p>
            <p class="teacherType">{{item.type}}</p>
          </div>
          <div class="itemCode">
            <vue-qr :text="item.code" :size="48" :margin="0"></vue-qr>
          </div>
        </div>
      </div>
      <div class="studentList"  v-if="studentList.length > 0">
        <div class="studentItem" v-for="(item, index) in studentList" v-bind:key="index" v-if="index < 7"><img :src="item.url" :title="item.name"></div>
        <div class="studentItem" v-if="studentLength == 8"><img :src="studentList[7].url" :title="studentList[7].name"></div>
        <div class="studentItem" v-if="studentLength > 8">
          <div class="itemMore">
            <span>{{studentLength}}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueQr from 'vue-qr'
export default {
  components:{
   VueQr
  },
  name: 'videoSideBar',
  props: ["courseId",'refresh'],
  data () {
    return {
      classImg: null, //课程封面
      isOpenInfo: false,
      showTeacherList: false,
      openUpload: false,
      isExpire: false,
      classGid: null,
      className: "班级名称", //班级名
      expireWords: "vip剩余天数", //vip过期时间
      teacherList: [], //老师列表
      studentList: [], //学生列表
      studentLength:8,
      courseList:[], //课程列表
      orderIndex: 0,
      hideSide: false
    }
  },
  created() {
    this.getClassInfo(this.courseId);
    // 非模块化课程 不用调用初始化模块状态接口 移除会根据 moduleId 作为判定是否是否是新课程 此处旧课程 
    localStorage.removeItem('lessonId')
    localStorage.removeItem('moduleId')
  },
  watch: {
    courseId: function (val) {
      this.courseId = val;
      this.getClassInfo(this.courseId);
      this.isOpenInfo = false;
      this.showTeacherList = false;
      localStorage.setItem('courseId', val);
    },
    refresh: function (val) {
      this.getClassInfo(this.courseId);
      this.isOpenInfo = false;
      this.showTeacherList = false;
    }
  },
  mounted() {
  },
  methods: {
    //显示隐藏侧栏
    tiggleSideBar() {
      this.hideSide = !this.hideSide;
      console.log(this.hideSide)
    },
    //向父组件传videoUrl
    postVideoUrl(url) {
      if (url) {
        this.$emit("listenSetUrl", url);
      } else {
        alert('视频地址不存在');
      }
    },
    //获取班级信息
    getClassInfo(id) {
      let that = this;
      let key = localStorage.getItem("key") || "";
      if (key != "") {
        let params = {};
        params.key = key;
        let gid = id || localStorage.getItem("courseId") || "";
       gid == "" ? "" : params.gid = gid;
        this.axios.post('/api/classroom/get',params).then((res)=>{
          if (res.data.code == 20000) {
            if (!res.data.data.lessons) {
              that.$emit("listenNotCourse");
            }
            let lessons = res.data.data.lessons;
            let teacherList = res.data.data.staff;
            let course_id = res.data.data.classroom.courseId;
            let course_type = res.data.data.course.course_type;
            that.getStudentInfo(res.data.data.classroom.gid);
            that.isExpire = res.data.data.is_expire; //是否过期
            that.classImg = res.data.data.classroom.courseCover; //课程封面
            that.className = res.data.data.classroom.title; //班级名
            that.expireWords = res.data.data.expireWords; //vip过期时间
            let newCourse = res.data.data.lesson.order;
            that.postVideoUrl(res.data.data.lesson.playInfo.playUrl);
            // if (res.data.data.is_expire === true) {
            //   that.$emit("listenExpire", 'true');
            // } else {
            //   that.$emit("listenExpire", 'false');
            // }
            //设置课程列表
            that.courseList = lessons.reduce(function(pre, val, index, arr) {
              newCourse = newCourse == 0 ? 1 : newCourse;
              let obj = {};
              let order = val.order;
              let locked = val.locked;
              obj.name = val.title;

              if (course_type) {
                let learn = val.learn;
                newCourse = res.data.data.last_progress;
                if (!locked && learn) {
                  obj.type = 1;
                } else if (!locked && !learn) {
                  obj.type = 2;
                } else if (locked) {
                  obj.type = 3;
                }
              } else {
                if (locked) {
                  obj.type = 3;
                } else if (!locked && order == newCourse) {
                  obj.type = 2;
                } else if (!locked && order < newCourse) {
                  obj.type = 1;
                }

              }
              obj.gid = course_id;
              obj.order = val.order;
              obj.openUpload = order == newCourse ? true : false;
              pre.push(obj);
              return pre;
            }, []);
            setTimeout(() => {
              let num = 62 * (newCourse-1);
              document.getElementById("courseList").scrollTop = num;
            }, 500);

            //设置老师列表
            that.teacherList = teacherList.reduce(function(pre, val, index, arr) {
              let obj = {
                img: val.avatarUrl,
                name: val.name,
                type: val.role,
                code: val.qrCode
              };
              pre.push(obj);
              return pre;
            }, []);

          } else {
            if (res.data.code == 40003) {
              that.$router.push({ path: "/login" });
              return;
            }
            alert(res.data.errMsg);
          }
        }).catch((error)=>{
          console.log(error);
        })
      }
    },
    // 获取今日打卡学生信息
    getStudentInfo(id) {
      let that = this;
      let key = localStorage.getItem("key") || "";
      let classroomId = id;
      if (key != "") {
        this.axios.get('/api/task/daily/v2',{params:{key: key,classroomId: classroomId}}).then((res)=>{
          if (res.data.code == 20000) {
            let daily = res.data.data.daily;
            that.studentLength = res.data.data.total_count;
            //设置课程列表
            that.studentList = daily.reduce(function(pre, val, index, arr) {
              let obj = {};
              obj.name = val.nickName || "";
              obj.url = val.avatarUrl;
              pre.push(obj);
              return pre;
            }, []);
          } else {
            if (res.data.code == 40003) {
              that.$router.push({ path: "/login" });
              return;
            }
            alert(res.data.errMsg);
          }
        }).catch((error)=>{
          console.log(error);
        })
      }
    },

    //展开老师信息
    optnInfo() {
      this.isOpenInfo = !this.isOpenInfo;
      this.showTeacherList = !this.showTeacherList;
    },
    //展开收起上传作业
    showOpenUpLoad(item,index) {
      // if (this.isExpire === true) {
      //   return;
      // }
      if (item.type == 2 || item.type == 1) {
        let courseList = this.courseList;
        for (let i = 0, len = courseList.length; i < len; i++) {
          if (courseList[i].type == 2 || courseList[i].type == 1) {
            if (i == index) {
              courseList[i].openUpload = !courseList[i].openUpload;
            } else {
              courseList[i].openUpload = false;
            }
          }
        }
        if (item.playUrl) {
          this.postVideoUrl(item.playUrl);
        } else {
          this.getVideoUrl(item,index);
        }
      } else {
        this.$emit("listenUpLoaad");
      }
    },
    //根据课程id获取某一课节的视频地址
    getVideoUrl(item,index) {
      console.log(item)
      let that = this;
      let key = localStorage.getItem("key") || "";
      let gid = item.gid;
      let progress = item.order;
      this.axios.get('/api/course/exact_lesson',{params:{key: key, courseId: gid, progress: progress}}).then((res)=>{
        if (res.data.code == 20000) {
          let lesson = res.data.data.lesson;
          that.courseList[index].playUrl = lesson.playInfo.playUrl;
          that.postVideoUrl(lesson.playInfo.playUrl);
        } else {
          if (res.data.code == 40003) {
            that.$router.push({ path: "/login" });
            return;
          }

          alert(res.data.errMsg)
        }
      }).catch((error)=>{
        console.log(error);
      })
    },
    //打开上传作业弹窗
    listenUpLoaad() {
      this.$emit("listenUpLoaad");
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .videoSideBar {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-color: #000;
  }
  .classInfo {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    padding: 20px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 327px;
    height: 200px;
    background-color: #1D1D1D;
  }
  .classImg {
    flex: 0 0 120px;
    width: 120px;
    height: 160px;
    box-sizing: border-box;
    border:2px solid rgba(255,255,255,0.12);
    border-radius: 10px;
  }
  .classDes {
    flex: 0 0 157px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    width: 157px;
    height: 160px;
  }
  .className {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    width: 157px;
    height: 90px;
  }
  .teacherList {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 137px;
    height: 28px;
    background:rgba(61,61,61,1);
    border-radius:4px;
    color:rgba(255,255,255,0.8);
    line-height:28px;
    font-size: 14px;
    cursor: pointer;
  }
  .nameInfo {
    width: 157px;
    height: 60px;
    line-height: 30px;
    font-size: 22px;
    color: #fff;
    text-align: left;
  }
  .vipInfo {
    width: 157px;
    height: 16px;
    font-size: 16px;
    color: rgba(255,255,255,0.6);
    text-align: left;
  }
  .openInfo {
    margin-left: 10px;
    width: 8px;
    height: 3px;
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAMAAACDi47UAAAAXVBMVEUAAAD///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////9RKvvlAAAAH3RSTlMAzAXJtEsnxMG7pJ2LfGtXNRYSCwGXgGdhXVNGOyMcK/A5/AAAAGNJREFUCNd1jlcSgCAMRF0EC4gI2Nv9jynYUGfMR/Kymexs9FODfm5r7ztDngStysHcsA2y+dJUjMJ6IC3iMdpJAmV93ruDjUA6BSfmvkhCQb178M/AUwjzzrVQQJJvWs3VzRsnowLIKT0XVgAAAABJRU5ErkJggg==');
    background-size: 8px 3px;
  }
  .rotate{
    transform-origin:center center;
    transform: rotate(180deg);
    transition: transform 0.2s;
  }
  .rotate1{
    transform-origin: center center;
    transform: rotate(270deg) !important;
    transition: transform 0.2s !important;
  }
  .teacherDes {
    position: absolute;
    top: 190px;
    left: 0;
    right: 0;
    padding: 10px 20px;
    box-sizing: border-box;
    width: 327px;
    height: auto;
    background-color: #3D3D3D;
    min-height: 100px;
    z-index: 92;
  }
  .tList {
    padding: 20px;
    box-sizing: border-box;
    width: 100%;
    height: auto;
    background:rgba(29,29,29,1);
    border-radius:4px;
  }
  .tItem {
    margin-bottom: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 50px;
  }
  .tItem:last-child {
    margin-bottom: 0;
  }
  .itemImg {
    flex: 0 0 50px;
    width: 50px;
    height: 50px;
    border-radius: 25px;
    background-color: #eee;
    overflow: hidden;
  }
  .itemImg img {
    width: 50px;
    height: 50px;
    border-radius: 25px;
  }
  .itemDes {
    flex: 0 0 147px;
    padding: 0 10px;
    box-sizing: border-box;
    width: 147px;
    height: 50px;
    text-align: left;
  }
  .itemCode {
    flex: 0 0 50px;
    width: 50px;
    height: 50px;
    background-color: #ccc;
    overflow: hidden;
  }
  .itemCode img {
    width: 50px;
    height: 50px;
  }
  .teacherName {
    width: 127px;
    height: 30px;
    font-size: 16px;
    color: #fff;
  }
  .teacherType {
    width: 127px;
    height: 20px;
    font-size: 14px;
    color: rgba(255,255,255,0.3);
  }
  .studentList {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 10px;
    padding: 6px 10px;
    box-sizing: border-box;
    width: 287px;
    height: 40px;
    background:rgba(29,29,29,1);
    border-radius:4px;
    overflow: hidden;
  }
  .studentItem {
    flex: 0 0 28px;
    border-radius: 14px;
    margin-right: 6px;
    width: 28px;
    height: 28px;
    overflow: hidden;
  }
  .studentItem img {
    width: 28px;
    height: 28px;
    border-radius: 14px;
    overflow: hidden;
  }
  .studentItem:last-child {
    margin-right: 0;
  }
  .itemMore {
    width: 28px;
    height: 28px;
    border-radius: 14px;
    background-color: #2B8DF7;
    font-size: 14px;
    color: #fff;
    text-align: center;
    line-height: 28px;
  }
  .courseList {
    position: absolute;
    top: 201px;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 10px 20px;
    box-sizing: border-box;
    width: 327px;
    height: auto;
    background-color: #1D1D1D;
    overflow: hidden;
    overflow-y: auto;
  }

.courseList::-webkit-scrollbar
{
    box-sizing: border-box;
    padding: 4px;
    width: 16px;
    height: 16px;
    background-color: rgba(244,245,247,0.2);;
}

/*定义滚动条轨道 内阴影+圆角*/
.courseList::-webkit-scrollbar-track
{
    box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    border-radius: 10px;
    background-color: rgba(244,245,247,0.2);;
}

/*定义滑块 内阴影+圆角*/
.courseList::-webkit-scrollbar-thumb
{
    width: 8px;
    border-radius: 10px;
    box-shadow: inset 0 0 6px rgba(0,0,0,.3);
    background-color: rgba(0,0,0,.4);
}

  .courseItem {
    margin-bottom: 10px;
    width: 287px;
    height: auto;
    min-height: 52px;
    border-radius:4px;
    overflow: hidden;
  }
  .notNew {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 10px;
    box-sizing: border-box;
    width: 287px;
    height: 52px;
    background-color: #3D3D3D;
    box-shadow:0px 2px 4px 0px rgba(93,100,110,0.1);
    cursor: pointer;
  }
  .notNew:hover {
    background-color: rgba(61,61,61,0.8);
  }
  .isNew {
    box-sizing: border-box;
    width: 287px;
    height: 92px;
    background-color: #3D3D3D;
    border:1px solid rgba(43,141,247,1);
    box-shadow:0px 2px 4px 0px rgba(93,100,110,0.1);
    background-color: rgba(43,141,247,1);
    cursor: pointer;
  }
  .isNew:hover {
    background-color: rgba(43,141,247,0.8);
  }
  .isNewCourseInfo {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 10px;
    box-sizing: border-box;
    width: 285px;
    height: 50px;
    border-radius: 4px 4px 0 0;
    border: none;
    background-color:#3D3D3D;
  }
  .uploadCourse {
    width: 285px;
    height:40px;
    background-color:rgba(43,141,247,1);
    border-radius: 0 0 4px 4px;
    font-size: 16px;
    color: #fff;
    line-height: 40px;
    text-align: center;
  }
  .courseItem:last-child {
    margin-bottom: 0;
  }
  .courseNum {
    flex: 0 0 27px;
    width: 27px;
    height: 16px;
    font-size: 16px;
    text-align: left;
    color: #fff;
  }
  .isNewCourseNum {
    flex: 0 0 27px;
    width: 27px;
    height: 16px;
    font-size: 16px;
    text-align: left;
    color: #fff;
  }
  .courseName {
    flex: 0 0 190px;
    width: 190px;
    height: auto;
    line-height: 20px;
    font-size: 20px;
    text-align: left;
    color: #fff;
  }
  .isNewCourseName {
    flex: 0 0 190px;
    width: 190px;
    height: auto;
    line-height: 20px;
    font-size: 20px;
    text-align: left;
    color: #fff;
  }
  .isNewCourseType {
    flex: 0 0 18px;
    height: 18px;
    text-align: center;
  }
  .courseType {
    flex: 0 0 18px;
    height: 18px;
    text-align: center;
  }
  .courseFinish {
    width: 18px;
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAkCAMAAADW3miqAAAAYFBMVEX///8AAAD///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////9oJYrRAAAAH3RSTlOAAGavbPt9dmJTTTQtIxEKhMay7tbPq5iL5N698qSjq89UxwAAAP5JREFUOMuV1OmOwjAMBGAT0jal6X3Asoff/y13iKC4lUNgflXVJ2WkxKaDSFOVpsjzwpRVI/8L5B2JOK+g2tIutt6jEyk5bVBrSI1pn6i1FIltV2QoGhOQ7KP3Cqiml6kDshRLF2rdkI+aY9YT4oFczPTMCyHuQE3sqIl5CF8glW4uX6sBKVVz/mE+3r9BjGaWTBiQQjHfLA1ITiF//WWtPG8NyB1deTw/K8NI9DhuYc6GUHncG5BH8YGZ545+M+itASll23FSDEglrgFRDIi4llk3IPKCJ9W47VPproohv390nTILbz/f9CCkR+r94UyPeXphpFfPp0ssvQ7/AWQ0DfaAZFZsAAAAAElFTkSuQmCC');
    background-size: 18px 18px;
     background-repeat: no-repeat;
  }
  .notUnlock {
    width: 15px;
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAkCAMAAACpD3pbAAAAS1BMVEX///8AAAD///////////////////////////////////////////////////////////////////////////////////////////+bUGJYAAAAGXRSTlOAAEN2JkoPCHpoPwNjVGoqPB5wXFlQMzAY45asjwAAANtJREFUOMvF1MmWgyAURdF3eSAIYpO2/v9LC0xoTAmzWjmzuzbKSAk5/bhdDM936ZDL7Famd7P8w3qiKvHBeqZD6sg3ivHdiquh2KNm+XqlQ2i47id1xfvFT7zzcdnCQ9weKXcJcyz8jPyDnI1b75wmo7TtxzOrsOZk6TJ54PEbPKxiDItFSUX2Yh1Am6FmZqOR2gX810wfuaMBp44GVB0NKDsa0HFb2RFsmy0ImlvKOjCWFi+IDHGuAi+GP1OPxBBnzxbGwkfk5eP7tlyh1UicclJNbAxPqvp7/AKShQZCmNcxgAAAAABJRU5ErkJggg==');
    background-size: 15px 18px;
    background-repeat: no-repeat;
  }
  .height0 {
    padding: 0;
    height: 0;
  }
  .top0 {
    top: 0;
  }
  .hideSideBar {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 50%;
    bottom: -20px;
    transform: translateX(-50%);
    width: 50px;
    height: 20px;
    background-color: #111;
    cursor: pointer;
    border-radius: 0 0 80px 80px;
    z-index: 90;
  }
  .packImg {
    width: 16px;
    height: 20px;
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAMAAABg3Am1AAAAS1BMVEUAAABvb29vb29xcXFvb29vb29vb29wcHBvb29wcHBvb29zc3Nvb29vb29vb29vb29wcHBwcHBvb29vb29wcHBvb29tbW1xcXFwcHB5njg2AAAAGHRSTlMACMUi6K5l5F6/px/XspJsU/Dec0ZBFU1lfuL6AAAAiElEQVRIx+XW2wqAIBAEUMvu2d1q//9Lo0dZGpFCSfdxOQOCyChCTTYuw2GuTqX0s2+JaDRWDREVGfJUm/6eHXnamFfQl8wXMhVfQZ//xNeuvke+8+inkF4MzM/QS+ZFDjwOvDoSvoS4EpJZ2/NJMNF8nmijSlhKkSdWa+3ai93p6zCvSgsfcwHzzCzP0kNNGAAAAABJRU5ErkJggg==");
    background-size: 16px 20px;
    background-repeat: no-repeat;
    transform: rotate(90deg);
  }
</style>
