<template>
  <div class="course">
    <div class="window">
      <div class="title">
        <h3>{{head}}</h3>
        <h4>{{headDes}}</h4>
      </div>
      <img :src="headImg" width="280px" height="auto" class="im" />
      <button class="refresh" @click="refresh">刷新</button>
    </div>
  </div>
</template>
<script>
  export default {
    props: ['head', 'headDes', 'headImg'],
    data() {
      return {
       
      }
    },
    created() {

    },
    methods: {
      refresh() {
        this.$emit("listenRefresh");
      }
    }
    
  }
</script>
<style scoped>
  * {
    margin: 0;
    padding: 0;
  }
  .window {
    position: relative;
    background-color: white;
    width: 430px;
    height: 468px;
  }
  
  .title {
    margin-left: 24px;
  }
  
  .title h3 {
    width: 200px;
    line-height: 28px;
    font-size: 28px;
    margin-top: 24px;
    font-family: SimHei;
    color: rgba(13, 20, 30, 1);
    font-weight: bold;
    text-align: left;
  }
  
  .title h4 {
    width: 380px;
    font-size: 18px;
    font-family: AdobeHeitiStd-Regular;
    color: #5D646E;
    margin-top: 10px;
    line-height: 18px;
    font-weight: 400;
    text-align: left;
  }
  
  .im {
    margin-top: 33px;
  }
  
  .refresh {
    position: absolute;
    bottom: 30px;
    left: 50%;
    transform: translateX(-50%);
    width: 130px;
    height: 46px;
    font-size: 20px;
    color: #fff;
    background:rgba(43,141,247,1);
    border-radius:26px;
    border: none;
    outline: none;
    cursor: pointer;
  }
  .refresh:active {
    background:rgba(43,141,247,0.8);
  }
  .zz {
    width: 100%;
    height: 100%;
    background-color: #000;
    filter: alpha(opacity=70);
    -moz-opacity: 0.7;
    opacity: 0.7;
    position: absolute;
    z-index: 100;
  }
  
  .course {
    width: 100%;
    height: 100%;
  }
</style>