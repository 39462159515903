<template>
  <div class="videoPlayBox">
    <video
      :src="videoid.videoSrc"
      controlslist="nodownload"
      class="video"
      :width="width"
      :height="height"
      ref="videoPaused"
      bindended="beginPlayVideo"
      controls
      preload
      :loop="isNewCourse ? false : true"
      @play="onPlay"
      @ended="onended"
    ></video>
  </div>
</template>

<script>
import { initModelStatus } from "../api/course";

export default {
  name: "videoPlay",
  props: ["videoData", "videoid", "isNewCourse"],
  data() {
    return {
      width: "800px",
      height: "500px",
      loop: false
    };
  },

  beforeUpdate() {},
  watch: {
    videoid: {
      handler: function(val, oldval) {
        console.log(val, oldval);
      },
      deep: true //对象内部的属性监听，也叫深度监听
    },
    "videoid.paused": function(val, oldval) {
      this.videoPauseFun(val);
    } //键路径必须加上引号
  },
  methods: {
    videoPauseFun(paused) {
      if (paused) {
        this.$refs.videoPaused.pause();
      } else {
        this.$refs.videoPaused.play();
      }
    },
    beginPlayVideo() {
      console.log("playvideo");
    },
    handlerParmas() {
      const parmas = {};
      parmas.course_id = +localStorage.getItem("courseId");
      parmas.module_id = +localStorage.getItem("moduleId");
      parmas.lesson_id = +localStorage.getItem("lessonId");
      return parmas;
    },
    onPlay(e) {
      if (!this.isNewCourse) {
        return;
      }
      const parmas = this.handlerParmas();
      parmas.module_finished = false;
      initModelStatus(parmas);
    },
    async onended(e) {
      if (!this.isNewCourse) {
        return;
      }
      const parmas = this.handlerParmas();
      parmas.module_finished = true;
      await initModelStatus(parmas);
      this.$emit("handleVideoEnd");
    }
  }
};
</script>
<style scoped>
.videoPlayBox {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
.video {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
  width: 100%;
  height: 100%;
  background-color: #000;
}
video::-internal-media-controls-download-button {
  display: none;
}

video::-webkit-media-controls-enclosure {
  overflow: hidden;
}

video::-webkit-media-controls-panel {
  width: calc(100% + 30px);
}
</style>


